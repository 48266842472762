.basket-details--container {
    width: 65%;
    
}

.delivery-options--container {
    margin-top: 0;
    
}

.faq-child--wrap {
    width: 33%;
    
}

.basket-timeline--wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
   	
}

.basket-process-timeline--container {
    width: 60%;
    padding: 0.75em 0;
    
    .multi-step-bar {
        padding-top: 1em;  
           
    }
    
}

.popular-container {
    padding: 1em 0 2em;
    
}


@media only screen and (min-width: 83.938em) {
.contact-cta--img {
    height: 275px;
        
}
    
}