.logo,
.logo:visited {
	margin: 1em 1em 1em 0;
	padding-top: 1em;
	padding-bottom: 1em;
	width: 200px;
}

.mast {
	padding: 0;

	.wrap {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		position: relative;
	}
	
}

.mast-inner{
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
	position: relative;
	
}

.mast--actions {
	justify-content: flex-end;
	max-width: 70%;
	max-width: calc(100% - 250px);

	.button-wrap {
		margin: 0 0 0 .4em;
		width: auto;
	}

}

.mast--telephone {
	display: none;
	margin: 0 1em 0 0;
	width: auto;
	
	@media only screen and (min-width: 65.250em) {

		display: block;
		margin-top: 10px;
		font-size: 15px;

	}

}

.mast--basket {
	margin: 0;
	padding: .75em 1em;
	width: auto;
	border-right: 0.25em solid white;

	
	&.mobile{
		display: none;
	}
}


.customer-account-buttons--container{
	display: flex;
	
}


.mast-logo-wrap{
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
}