.banner--promo {
	width: 40%;

}

.sort-by--container-mobile{
    display: none;
}

.sort-by--container{
    display: flex;
    padding: 2em 0em;
}