.litho_warning{color:#00aeef !important;}
.header-links a{color:#fff !important;}
.header-links a:hover{color:#fff !important;text-decoration: underline;}
.header-links a:active{color:#fff !important;text-decoration: underline;}
.bird-product-img{float:right;width:32%;}
.center-text{width:50%;}
@media screen and (max-width:1099px){
	.bird-product-img{margin-right:130px;width:300px;margin-top:16px;}
}
@media screen and (max-width:894px){
	.bird-product-img{margin-right:30px;height:330px;width:330px;}
}
@media screen and (max-width:844px){
	.bird-product-img{display:none;}
	.center-text{width:100% !important;}
}
.center .about-main-content .image_fix p{float:left;width:480px;margin-top:0px;}
.center .about-main-content .image_fix img{float:left;margin-top:17px;width:270px;}
@media screen and (max-width:1116px){
	.center .about-main-content .image_fix img{width:20%;}
	.center .about-main-content .image_fix p{width:70%;}
}
@media screen and (max-width:925px){
	.sidebar-left{display:none;}
}
@media screen and (max-width:600px){
	.center .about-main-content .image_fix img{display:none;}
	.center .about-main-content .image_fix p{width:90%;}
}
.contact{float:left;width:70%;}
.contact .image_fix img{float:right;width:40%;margin-top:40px;}
.contact .image_fix p{width:380px;float:left;}
.contact .image_fix span{background-color:transparent !important;}
.contact .image_fix a{color:#00aeef;text-decoration:none;}
.contact .image_fix a:hover{color:#45545f;text-decoration:underline;}
.contact .image_fix h1{margin-top:35px;margin-bottom:0px;}
@media screen and (max-width:1045px){
	.contact{margin-left:20px;}
}
@media screen and (max-width:925px){
	.contact{width:95%;}
}
@media screen and (max-width:681px){
	.contact .image_fix img{width:30%;}
}
@media screen and (max-width:600px){
	.contact .image_fix img{display:none;}
}
@media screen and (max-width:400px){
	.contact .image_fix p{width:90%;}
}
.slider{width:100%;background:#efefef;float:left;padding-bottom:30px;}
.slider-center{width:1080px;margin:0 auto;}
.offer1{width:802px;height:402px;margin-top:30px;float:left;border:solid 1px #bcc0c4;}
.offer2, .offer3{float:right;height:192px;width:252px;border:solid 1px #bcc0c4;}
.offer2{margin-top:30px;}
.offer3{margin-top:18px;}
.general-full.flexslider-container{padding:0;}
.flexslider{border:0;margin-bottom:0;}
.flex-control-nav{bottom:10px;.flexslider-google &{ display: none;}}

@media screen and (max-width:420px){
	.flex-control-nav{right:10px;width:auto;}
}
.flex-control-paging li a{width:10px;height:10px;display:block;background:white;cursor:pointer;text-indent:-9999px;-webkit-box-shadow:inset 0 0 0 rgba(0, 0, 0, 0.3);-moz-box-shadow:inset 0 0 0 rgba(0, 0, 0, 0.3);-o-box-shadow:inset 0 0 0 rgba(0, 0, 0, 0.3);box-shadow:inset 0 0 0 rgba(0, 0, 0, 0.3);-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;}
.flex-control-paging li a:hover{background:#3a3d3e;}
.flex-control-paging li a.flex-active{display: none;}
.flex-control-paging li a.flex-active:hover{background:#00aeef;}
.content-container, .services-container, .general-container{width:100%;position:relative;}
.content-center, .services-center, .general-center{width:100%;max-width:1260px;position:relative;margin:0 auto;float:none;}
.content-center{display:flex;flex-wrap:wrap;}
.general-center{font-size:17px !important;line-height:1.6em;color:#1B1717;font-family:'Lato', 'Arial', sans-serif;}
.general-center p,
.general-center p span,
.general-center p font{font-family:'Lato', 'Arial', sans-serif !important;font-size:17px;line-height:1.3;color:#1B1717;}
.general-half--wrap{clear: both;display: block;margin-bottom:2em;overflow: hidden;}
.general-full{width:100%;padding:40px 30px 40px 30px;box-sizing:border-box;display:inline-block;float:left;}
.content-intro{font-weight:900;}
.general-two-third{width:66.6666666%;padding:40px 50px 40px 30px;box-sizing:border-box;display:inline-block;float:left;}
.general-two-third .featured-image{margin-bottom:30px;}
.general-two-third p span,
.general-two-third div span,
.general-two-third div,
.general-two-third p font,
.general-two-third div font{font-family:'Lato', 'Arial', sans-serif !important;font-size:17px !important;font-weight:400;line-height:1.6em !important;background:transparent !important;}
.general-two-third a,
.general-half a{color:#00aeef;}
.general-two-third a:hover,
.general-half a:hover{color:#00aeef;}
.general-half{width:50%;padding:40px 50px 40px 30px;box-sizing:border-box;display:inline-block;float:left;}
.general-half a.edit-order {
    float: left;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    padding: 5px 15px 5px 15px;
    margin-left: 15px;
    margin-right: 10px;
    margin-top: 20px;
    background: #45545f;
}
#basket_form .general-half{padding:0 30px 0 0;}
.checkoutstepsummary .general-half{padding:40px 30px 40px 30px;}
#basket_form h2{font-size:36px;line-height:1.2em;float:left;width:100%;}
.general-half h2{font-size:36px;line-height:1.2em;}
.general-half .link_button{float:left;display:block;margin-top:20px;width:100%;color:#fff;background-color:#00aeef;padding:6px 20px 6px 20px;text-align:center;border:none;transition: background-color 0.3s ease-in-out,
				color 0.3s ease-in-out;}
.general-half .link_button:hover{background-color: rgb(85, 85, 85);color:white;}
.general-one-third{width:33.3333333%;padding:40px 30px 40px 30px;box-sizing:border-box;display:inline-block;float:right;}
.content-center{background-color:#00aeef;}
.content-two-third, .content-full{width:66.6666666%;background-color:#1b1717;padding:35px 50px 35px 30px;box-sizing:border-box;color:white;font-family:'Lato', 'Arial', sans-serif;display:inline-block;float:left;}
.content-two-third h1, .content-full h1{text-transform:uppercase;font-family:'Lato', 'Arial', sans-serif !important;color:white !important;font-size:36px !important;margin-top:0;font-weight: bold;}
.content-two-third p, .content-one-third p{font-family:'Lato', 'Arial', sans-serif !important;color:white !important;font-size:17px;line-height:1.6em;font-weight:300;}
.content-two-third p span.red{background:#db3020;padding:5px;margin-top:10px;}
.content-one-third h2{text-transform:uppercase;font-family:'Lato', 'Arial', sans-serif !important;color:white !important;font-size:36px !important;margin-top:0;}
.content-two-third p font{font-family:'Lato', 'Arial', sans-serif !important;color:white !important;font-size:17px !important;line-height:1.6em;font-weight:300;}
.content-two-third p span,
.content-two-third div span,
.content-two-third div,
.content-two-third p font,
.content-two-third div font{font-family:'Lato', 'Arial', sans-serif !important;color:white !important;font-size:17px !important;font-weight:300;line-height:1.6em !important;}
.content-two-third .breadcrumb{ text-transform: none; }
.content-full{width:100%;}
.content-full h1{margin-bottom:0;}
.products-specific-content{padding-top:20px;}

.breadcrumb {
	color: #fff;
	font-size: .75em;
	margin-bottom: .85em;
	text-transform: uppercase;
}

.breadcrumb .seperator {
	display: inline-block;
	padding: 0 .2em;
}

.breadcrumb a {
	color:#fff;
	text-decoration:none;
}

.breadcrumb a:hover {
	text-decoration:underline;
}

.banner--content.bg-image .breadcrumb,
.banner--content.bg-image .breadcrumb a,
.banner--content.bg-image .breadcrumb a:hover {
	color: #1B1717;
}

.banner--content.bg-image .breadcrumb-reversed a, .breadcrumb-reversed .seperator {
	color:#fff;
	text-decoration:none;
}

.content-one-third{width:33.3333333%;background-color:#00aeef;float:right;padding:30px 30px 40px 30px;box-sizing:border-box;color:white;display:inline-block;min-height:340px;}
.content-one-third h3 {
	margin-top:-14px;
	font-size:40px;
	line-height: 1;
}
.content-one-third h3 span.free{font-size:124px;}
.content-one-third h4{margin-bottom:0;font-weight:300;}
@media screen and (max-width:768px){.content-one-third, .content-two-third,
.general-one-third, .general-two-third, .general-half{width:100%;float:left;padding:1.5em 5%;}
}
@media screen and (max-width:1060px){.content-one-third h3 span.free{font-size:100px;}
}
@media screen and (max-width:880px){.content-one-third h3{font-size:34px;}
.content-one-third h3 span.free{font-size:90px;}
}
@media screen and (max-width:800px){.content-one-third h3 span.free{font-size:80px;}
}
.services-container{margin-bottom:30px;}
.services-half{padding:40px 50px 40px 30px;box-sizing:border-box;font-family:'Lato', 'Arial', sans-serif;display:inline-block;float:left;color:#1B1717;}
.services-half h2{font-size:36px;}
.services-half h3{font-size:24px;}
.services-half p{font-size:17px;line-height:1.6em;}
.services-half a{background:transparent;color:#00aeef;display:inline-block;margin-top:0;}
.services-half a:hover{color:#00aeef;}
@media screen and (max-width:804px){.services-half{width:100%;}
.services-half.last{padding-top:0;}
}
.service-list{width:100%;float:left;padding:2em 0;}
.service-list .service{width:25%;text-align:center;float:left;font-size:17px;color:#1B1717;padding:0 10px;}
.service-list .service img,
.service-list .service svg{margin-bottom:15px;margin-top:3px;}
.service-list .service.file-check svg,
.service-list .service.file-check img{margin-top:0;}
@media screen and (max-width:900px){.service-list .service{width:25%;margin-top:30px;}
}
@media screen and (max-width:650px){.service-list .service{width:50%;}
}
.resource-link{width:100%;margin-bottom:0;}
.ainline{padding:20px 15px 20px 55px;background-color:#00aeef;color:#fff;font-size:14px;margin:0px 0px 0px 0px;float:left;background-image:url(/img/pdf_icon.png);background-repeat:no-repeat;background-position:15px 15px;background-size:23px 30px;line-height:1.3em;margin-right:10px;width:100%;text-transform: uppercase;text-align: center;transition: background-color 0.25s ease-in-out;font-weight: 700;}
.ainline:hover{background-color:darken(#00aeef, 9%);;background-image:url(/img/pdf_icon.png);background-repeat:no-repeat;background-size:23px 30px;}
.blog_right p,
.blog_left p{display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;}
.left{width:670px;float:left;}
.left h1{font-size:26px;color:#00aeef;}
.left p{font-size:14px;color:#45545f;}
.blue{background:#00aeef;}
.grey{background:#45545f;margin-left:30px;}
.printing{width:320px;height:620px;padding:15px;color:#fff;box-shadow:3px 3px 10px #CACACA;float:left;}
.printing img{width:290px;}
.printing p{font-size:12px;color:#fff}
.printing hr{background:white;border:none;height:1px;}
.printing h2{font-size:16px;margin-top:0px;}
.printing h3{font-size:16px;margin-bottom:0px;}
.printing ul{padding-left:0px;margin:0px;}
.printing ul li{list-style-type:none;margin-bottom:13px;font-size:13px;}
.printing li a.digital{color:white;}
.printing li a.digital:hover{color:#45545f;}
.printing li a.litho{color:white;}
.printing li a.litho:hover{color:#00aeef;}
.right{width:410px;float:right;text-align:center;margin-top:20px;}
.popular-container{width:100%;color:white;margin: 0 auto;position:relative;}
.popular-center{width:100%;max-width:1260px;padding:20px 0px 30px 0px;margin: 0 auto;height:auto;position:relative;background:#ffffff;overflow:auto;}
.popular-center h2{font-size:29px;color:#1b1717;}
.popular-product{float:left;width:23%;text-align:left;margin-top:10px;position:relative;}
.popular-product img{width:100%;height:209px;float:left;position:relative;}
@media screen and (max-width:593px){.popular-product p{width:100%;text-align:center;}
}
.popular-product h2{font-size:24px;margin-bottom:8px;font-weight:700;padding-right:20px;line-height:1.4em;float:left;position:relative;}
.popular-product h2 span{font-weight:400;color:#999999;}
.popular-product h3{font-size:18px;color:#1b1717;margin-top:0;font-weight:400;padding-right:20px;line-height:1.4em;float:left;position:relative;clear:both;}
.products-container{width:100%;float:left;position:relative;}
.products-center{width:100%;max-width:1260px;position:relative;margin:0 auto;}
.products-full{width:100%;box-sizing:border-box;padding:30px 30px;font-size:17px !important;line-height:1.6em;color:#1B1717;}
.products-full h2{font-size:36px;}
.products-full h4{margin-bottom:0;}
.products-full ul{margin-top:0;margin-bottom:0;}
.products-full .turnaround{margin-bottom:0;}
.digital-container, .litho-container{width:100%;color:white;margin:20px auto 0 auto;position:relative;float:left;}
.digital-center, .litho-center{width:100%;max-width:1260px;padding:20px 30px 30px 30px;margin:0 auto;position:relative;background:#3a3d3e;overflow:auto;background:-moz-linear-gradient(-45deg, #3a3d3e 0%, #3a3d3e 50%, #484b4d 50%, #484b4d 100%);background:-webkit-gradient(linear, left top, right bottom, color-stop(0%,#3a3d3e), color-stop(50%,#3a3d3e), color-stop(50%,#484b4d), color-stop(100%,#484b4d));background:-webkit-linear-gradient(-45deg, #3a3d3e 0%,#3a3d3e 50%,#484b4d 50%,#484b4d 100%);background:-o-linear-gradient(-45deg, #3a3d3e 0%,#3a3d3e 50%,#484b4d 50%,#484b4d 100%);background:-ms-linear-gradient(-45deg, #3a3d3e 0%,#3a3d3e 50%,#484b4d 50%,#484b4d 100%);background:linear-gradient(135deg, #3a3d3e 0%,#3a3d3e 50%,#484b4d 50%,#484b4d 100%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#3a3d3e', endColorstr='#484b4d',GradientType=1);}
.digital-center{min-height:1010px;}
.litho-center{min-height:420px;}
.digital-center h2, .litho-center h2{font-size:36px;}
.digital-product, .litho-product{float:left;width:25%;text-align:left;margin-bottom:40px;position:relative;}
.digital-product img, .litho-product img{width:100%;height:auto;float:left;position:relative;}
@media screen and (max-width:593px){.digital-product p, .litho-product p{width:100%;text-align:center;}
}
.digital-product h2, .litho-product h2{font-size:17px;margin-bottom:0;font-weight:700;color:#00aeef;padding-right:20px;line-height:1.4em;float:left;position:relative;}
.digital-product h2 span, .litho-product h2 span{font-weight:400;color:#999999;}
.digital-product h3, .litho-product h3{font-size:17px;color:white;margin-top:0;font-weight:400;padding-right:20px;line-height:1.4em;float:left;position:relative;clear:both;}
@media screen and (max-width:1080px){.digital-product, .litho-product{width:50%;}
}
@media screen and (max-width:593px){.digital-product, .litho-product{width:100%;}
}
.product-list-container{position:relative;float:left;width:100%;}
.product-list{width:100%;max-width:1260px;margin:0 auto;}
.product-list h2{font-size:36px;padding:0 30px;}
.product-list p{padding:0 30px;font-family:'Lato', 'Arial', sans-serif !important;font-size:17px !important;line-height:1.6em !important;}
.product-list p a{color:#00aeef;}
.product-list p a:hover{color:#009ac2;}
.product-name{display:inline-block;}
.category-product{width:100%;background-color:#3a3d3e;margin-bottom:1px;font-size:1.2em;color:#009ac2;padding:17px 30px;}
.category-product .product-size{color:white;margin-right:40px;font-size:40px;vertical-align:middle;}
.category-product .product-label{color:white;margin-right:10px;vertical-align:middle;font-weight:300;}
.category-product .product-separator{color:white;vertical-align:middle;font-weight:300;}
.category-product a{color:#00aeef;text-decoration:none;vertical-align:middle;}
.category-product a:hover{color:#009ac2;}
.category-product-button{float:right;display:block;width:135px;height:30px;line-height:30px;color:white;background-color:#009ac2;text-align:center;font-size:0.7em;}
.category-product-button:hover{background-color:#46545f;}
.form_errors h3{color:#099abe;margin-top:20px;}
.form_errors{padding-left:280px;padding-top:17px;}
.testimonials{color:#00aeef;width:100%;float:left;font-size:14px;}
.testimonials-center{margin:0 auto;width:1080px;}
.testimonials-center h2{color:#45545f;font-size:19px;margin-top:30px;}
.left-testimonial, .right-testimonial{margin-top:10px;}
.left-testimonial{width:480px;float:left;margin-right:50px;}
.right-testimonial{width:480px;float:left;margin-left:50px;}
.testimonials-center p{width:420px;margin:0px 5px 0px 8px;}
.testimonials-center img.start-quote, .testimonials-center p{float:left}
.testimonials-center img.end-quote{position:relative;bottom:0px;left:0%;}
.testimonials-center p.author{color:#45545f;font-size:13px;margin-left:37px;margin-top:15px;width:400px;}
.about-center{width:1080px;margin:0 auto;}
.sidebar-left{float:left;width:300px;}
.sidebar{width:230px;float:left;padding:10px;color:#fff;box-shadow:2px 2px 10px #777;}
.sidebar h2{margin:0px;font-size:18px;}
.digital{background:#00aeef;margin-top:40px;}
.litho{background:#45545f;margin-top:20px;}
.sidebar ul.upper{padding-left:0px;}
.sidebar ul.upper li{list-style-type:none;margin-bottom:12px;font-size:14px;cursor:pointer;}
.sidebar ul.upper li span{float:right;cursor:pointer;}
.sidebar ul.upper a{text-decoration:none;color:#fff;}
.sidebar ul.upper li ul{padding-left:15px;margin-top:10px;}
.sidebar ul.upper li ul li{font-size:12px;font-style:italic;}
.sidebar ul.upper .sub-menu{display:none;}
.blog{width:230px;padding:10px;float:left;background:#efefef;border:1px solid #c7cbcf;margin-top:20px;box-shadow:2px 2px 10px #777;}
.blog h2{color:#00aeef;font-size:18px;margin-top:0px;}
.blog p{color:#45545f;font-size:11px;}
.blog a{text-decoration:none;color:#00aeef;font-size:11px;}
.about-main-content{width:760px;float:left;margin-left:20px;margin-top:40px;color:#45545f;}
.center .about-main-content p{width:auto;}
.center .about-main-content div{display:inline;float:left;}
.about-sidebar{float:right;width:260px;}
.about-main-content img{float:left;margin-top:10px;}
.about-main-content h1{font-size:23px;color:#00aeef;}
.about-main-content h2{color:#00aeef;font-size:18px;}
.about-main-content p{font-size:14px;}
.about-main-content ul{padding-left:15px;}
.about-main-content ul li{font-size:14px;margin-bottom:15px}
.three-products{width:760px;float:right;background:#efefef;margin-top:20px;border:1px solid #c7cbcf;box-shadow:0px 5px 10px #bebebe;color:#45545f;padding:10px;margin-right:20px;}
.three-products h2{font-size:19px;margin-left:23px;}
.three-products img{width:195px;}
.pop-product{float:left;width:246px;text-align:center;margin-top:10px;}
.pop-product h2{font-size:17px;margin-bottom:0px;margin-left:0px;}
.pop-product h2 span{font-weight:400;font-size:14px;}
.pop-product h3{font-size:16px;color:#00aeef;margin-top:0px;}
.testimonials-main-content{width:760px;float:left;margin-left:20px;margin-top:40px;}
.testimonials-main-content h1{color:#00aeef;font-size:25px;margin:0px;}
.testimonials-main-content p{font-size:14px;}
.full-testimonial{width:760px;padding:20px;float:left;background:#efefef;border:1px solid #c7cbcf;box-shadow:0px 5px 10px #bebebe;}
.testimonials-main-content .full-testimonial p{width:670px;margin:0px 5px 0px 8px;color:#00aeef;}
.right-testimonials{width:580px;padding:20px;float:right;background:#00aeef;border:1px solid #c7cbcf;box-shadow:0px 5px 10px #bebebe;margin-top:40px;margin-bottom:40px;}
.testimonials-main-content .right-testimonials p{width:490px;margin:0px 5px 0px 8px;color:#fff;}
.left-testimonials{width:580px;padding:20px;float:left;background:#00aeef;border:1px solid #c7cbcf;box-shadow:0px 5px 10px #bebebe;margin-top:40px;margin-bottom:40px;}
.testimonials-main-content .left-testimonials p{width:490px;margin:0px 5px 0px 8px;color:#fff;}
.testimonials-main-content img.start-quote, .testimonials-main-content p{float:left}
.testimonials-main-content img.end-quote{position:relative;bottom:0px;left:34%;}
.testimonials-main-content p.author{color:#45545f;font-size:13px;margin-left:37px;margin-top:15px;width:400px;}
.title{margin:0 auto;font-size:15px;color:#00aeef;}
.title h1{font-size:23px;color:#00aeef;}
.center{width:1080px;margin:0 auto;color:#45545f;}
.digital-products h2{color:#00aeef;}
.litho-products h2{color:#00aeef;}
.digital-products{width:100%;float:left;}
.litho-products{width:100%;background:#d0d4d7;float:left;margin-top:20px;}
.center h2{font-size:18px;}
.center p{font-size:14px;width:630px;}
.center .search-results p{width:auto;}
.single-product{width:252px;text-align:center;float:left;margin-right:18px;margin-bottom:20px;}
.single-product h2{margin-top:0px;}
.digital-products .single-product img{box-shadow:5px 5px 10px 0px #ececec;margin-bottom:10px;}
.litho-products .single-product img{box-shadow:5px 5px 10px 0px #bebebe;margin-bottom:10px;}
p.checkout-steps{color:#ccc; margin: 0;}
p.checkout-steps b{color:#00aeef;}
.checkout-order-content{float:left;width:100%;color:#45545f;}
.checkout-order-content h1{color:#00aeef;}
.checkout-order-content p{font-size:14px !important; margin: 0;}
.checkout-order-content p span {border-right: 1px solid #00aeef; font-size:14px !important; margin-right: .5em; padding-right: .5em;}
.checkout-order-content p span:last-of-type {border-width: 0;}
.checkout-order-content table{border-spacing:0px;width:100%;padding-top:35px;}
.checkout-order-content table thead{background:#45545f;color:white;font-size:14px;}
.checkout-order-content table thead td{padding:10px 10px 10px 10px;}
.checkout-order-content tbody td{padding:10px;vertical-align:top;font-size:14px;background:#EFEFEF;border-bottom:3px solid white;}
.checkout-order-content tbody td.total-column,
.checkout-order-content thead td.total-column{text-align:right;}
.checkout-order-content td.empty-basket{width:100%;}
.order-price-summary{float:right;width:100%;color:#45545f;margin-bottom:10px;background:#efefef;padding:10px 0 20px 10px;overflow: hidden;}
.order-price-summary table{padding-top:0;width:200px;float:right;}
.order-price-summary table td{border-bottom:0; padding: 5px 10px;}
.order-price-summary table td .total{font-weight:bold;color:#00aeef;}
.order-price-summary p{margin-top:15px;width:auto;font-size:13px !important;text-align:right;}
.order-price-summary p span{font-size:13px !important;}
.order-price-summary span.total{color:#00aeef;}
.basket-buttons{float:left;margin-top:10px;padding: 0 10px 0 0;}
.basket-buttons .update-basket{background:#45545f;}
.basket-buttons .update-basket:hover{background:#00aeef;}
a.tc{color:#00aeef}
a.tc:hover{color:#009ac2;}
.checkout-wrapper{width:100%;float:left;}
.checkout-summary{width:1080px;margin:0 auto;}
.sidebar-left img{margin-top:20px;}
.checkout-summary-content{float:left;width:100%;color:#45545f;padding:20px 30px;}
.checkout-summary-content hr{width:100%;}
.checkout-summary-content h1{font-size:28px;color:#00aeef;}
.checkout-summary-content h2{font-size:18px;margin:30px 0px 20px 0px;}
.checkout-summary-content table{margin-left:20px;}
.checkout-summary-content table tr td{width:150px;padding-bottom:50px;font-size:14px;vertical-align:top;}
.checkout-summary-content table tr td span{color:#00aeef;}
.checkout-summary-content input[type=checkbox]{margin:10px 20px 70px 20px;float:left;}
.checkout-summary-content a.edit-button{font-size:14px;text-transform:uppercase;color:white;padding:10px 15px 10px 15px;background:#00aeef;float:right;margin:-75px 0px 0px 0px;}
.checkout-summary-content a.edit-button.change-name + .edit-button{margin-top:-120px;}
#checkout-change-delivery-name{display:none;}
#checkout-change-delivery-name form{padding-bottom:3em;}
#checkout-change-delivery-name input[type=text]{display:block;font-size:1em;padding:0.5em;width:100%;}
#checkout-change-delivery-name input[type=submit]{background-color:#00aeef;border:0;color:white;padding:0.5em 1em;margin-top:0.5em;font-size:0.9em;text-transform:uppercase;float:right;}
.checkout-summary-content a.edit-button:hover{background:#45545f;}
.checkout-summary-content a.tandc{font-size:14px;color:#00aeef;}
.checkout-summary-content a.tandc:hover{color:#45545f;}
a.edit-order{float:left;color:white;text-transform:uppercase;font-size:14px;padding:5px 15px 5px 15px;margin-left:15px;margin-right:10px;margin-top:20px;background:#45545f;}
a.edit-order:hover{background:#00aeef;}
a.proceed{color:white;text-transform:uppercase;background:#00aeef;font-size:14px;padding:5px 15px 5px 15px;margin-right:0;margin-top:0;}
a.proceed_left{margin-top:1em;width: 100%;display: block;
text-align: center;}
#updateaddressform table{
    width:60%;
}
#updateaddressform .td_label{
    width:10%;
}
#updateaddressform .td_input{
	width:100%;
	
	input{
		height: 35px;
		background: #efefef;
		outline: none;
		padding-left: 7px;
	}
	
}
#updateaddressform input[type="text"]{

}
@media screen and (max-width:600px){
    a.proceed{margin-top:20px;}
    #updateaddressform table{
        width:100%;
    }
}
a.proceed:hover{background:#45545f;}
.checkout-payment{width:1080px;margin:0 auto;}
.checkout-payment-content{margin-top:20px;float:left;width:780px;color:#45545f;}
.checkout-payment-content p{margin:25px 0px 0px 0px;}
.checkout-payment-content span.info{font-size:12px;margin-left:15px;}
.checkout-payment-content span.name{float:left;display:block;width:200px;font-weight:bold;font-size:16px;line-height:28px;height:28px;}
.checkout-payment-content input{float:left;height:25px;}
.checkout-payment-content select{height:25px;}
.checkout-payment-content select.l{width:240px;}
.checkout-payment-content input.l{width:240px;}
.checkout-payment-content input.m{width:170px;}
.checkout-payment-content input.s{width:60px;}
.checkout-upload{width:1080px;margin:0 auto;}
.checkout-upload-content{margin-top:20px;float:left;width:780px;color:#45545f;}
.checkout-upload-content h1{font-size:32px;color:#00aeef;}
.checkout-upload-content h2{font-size:15px;}
.checkout-upload-content p{font-size:13px;}
.checkout-upload-content p.small{font-size:12px;}
.quotes{background:#efefef;float:left;width:100%;border:solid 1px #c7cbcf;box-shadow:5px 5px 10px #ccc;font-size:13px;padding-bottom:10px;}
.quotes table{width:65%;float:left;}
.quotes table tbody tr td{padding:15px 15px 5px 15px;}
.right-buttons{width:150px;float:left;text-align:center;}
.right-buttons a.grey{background:#45545f;margin:0px 10px 0px 10px;}
.right-buttons a.grey:hover{background:#00aeef;}
.right-buttons a.blue{background:#00aeef;margin:30px 10px 0px 10px;}
.right-buttons a.blue:hover{background:#45545f;}
.right-buttons a{padding:5px 10px 5px 10px;text-decoration:none;color:white;display:block;}
a.new-quote{background:#00aeef;margin:30px 10px 20px 0px;padding:5px 10px 5px 10px;text-decoration:none;color:white;display:block;float:left;font-size:13px;}
a.new-quote:hover{background:#45545f;}
.my-orders{width:100%;float:left;margin-top:5px;}
.upload-left{width:60%;float:left;}
.upload-left p.small span{color:#db3020;}
.upload-right{width:100%;float:left;background:#00aeef;padding:0px 30px 10px 30px;color:#fff;}
.upload-right p{width:auto;color:#fff;}
.orders{width:100%;float:left;}
.orders div.awaiting_artwork,
.orders div.artwork_received,
.orders div.general{width:100%;font-size:14px;overflow:auto;margin-top:2em;}
.orders div.awaiting_artwork .order-details,
.orders div.artwork_received .order-details,
.orders div.general .order-details{color:white;padding:10px 20px;width:100%;}
.orders div.awaiting_artwork .order-details{background:#db3020;}
.orders div.artwork_received .order-details{background:#1B1717;}
.orders div.general .order-details{background:#45545f;}
.orders div.awaiting_artwork .order-item,
.orders div.artwork_received .order-item,
.orders div.general .order-item{width:100%;background:#efefef;display: flex;flex-direction: column;}
.orders div.awaiting_artwork .order-details .order-no,
.orders div.artwork_received .order-details .order-no,
.orders div.general .order-details .order-no{width:40%;}
.orders div.awaiting_artwork .order-details .order-date,
.orders div.artwork_received .order-details .order-date,
.orders div.general .order-details .order-date{width:15%;text-align:center;}
.orders div.awaiting_artwork .order-details .order-amount,
.orders div.artwork_received .order-details .order-amount,
.orders div.general .order-details .order-amount{width:15%;text-align:center;}
.orders div.awaiting_artwork .order-details .order-status,
.orders div.artwork_received .order-details .order-status,
.orders div.general .order-details .order-status{width:30%;text-align:right;}
.orders div.awaiting_artwork .order-item .order-info,
.orders div.artwork_received .order-item .order-info,
.orders div.general .order-item .order-info{width:40%;}
.orders div.awaiting_artwork .order-item .order-status,
.orders div.artwork_received .order-item .order-status,
.orders div.general .order-item .order-status{width:30%;text-align:center;}
.orders div.awaiting_artwork .order-item .order-upload,
.orders div.artwork_received .order-item .order-upload,
.orders div.general .order-item .order-upload{text-align:right;}
.orders div.general .order-item .order-upload{}
@media screen and (max-width:620px){.orders div.awaiting_artwork .order-details span,
.orders div.artwork_received .order-details span,
.orders div.general .order-details span{width:100%;text-align:left !important;}
.orders div.awaiting_artwork .order-item span,
.orders div.artwork_received .order-item span,
.orders div.general .order-item span{margin-top:5px;}
.orders div.awaiting_artwork .order-item span:first-of-type,
.orders div.artwork_received .order-item span:first-of-type,
.orders div.general .order-item span:first-of-type{margin-top:0;}
}

.order a{
	text-decoration: underline;
	&:hover,
	&:focus{
		color: #015b7c;
	}
}
.orders a.upload-artwork{color:#ED1C24;font-size:14px;text-decoration: underline;}
.orders a.upload-artwork:hover{color:#015b7c;font-size:14px;}
.orders a.resend-invoice{color:#00aeef;font-size:14px;text-decoration: underline;}
.orders a.resend-invoice:hover{font-size:14px;text-decoration: none;color:#015b7c;}
.orders a.upload-files{background-color:#00aeef;padding:7px 30px;flex-grow: 0;color:white;display:flex;font-size:13px;font-weight: bold;  align-self: flex-start; flex-grow: 0; flex-shrink: 1;max-width: 222px;justify-content: center;margin: 0.5em 0;width: 100%;transition: background-color 0.3s ease-in-out;
	&.reupload{
		background-color:#db3020;transition: background-color 0.3s ease-in-out;
		
		&:hover,
		&:focus{
			background-color:#b92618;
		}
	}
}
.orders a.upload-files:hover{background-color:#015b7c;}
.category-landing-content{float:left;width:415px;margin-right:10px;}
.category-landing-content p{width:415px;}
.single-specific-product{float:left;width:100%;border-bottom:1px solid #45545f;padding:50px 0;}
.single-specific-product:last-child{border-bottom:0;padding-bottom:30px;}
.single-specific-product:first-child{padding-top:30px;}
.left-specific-product{float:left;width:320px;margin-right:30px;}
.right-specific-product{float:left;width:calc(100% - 350px);}
.right-specific-product h2{font-size:20px;text-transform:uppercase;color:#00aeef;}
.right-specific-product p{font-size:13px;color:#45545f;}
.right-specific-product a{background:#00aeef;padding:5px 10px 5px 10px;text-decoration:none;color:white;display:block;font-size:13px;width:120px;text-align:center;}
.right-specific-product a:hover{background:#45545f;}
footer{width:100%;color:#45545f;max-width:1260px;position:relative;margin:0 auto;}
.footer-center{width:100%;max-width:1260px;margin:0 auto;font-size:12px;background:#1b1717;float:left;padding:20px 30px 20px 30px;}
.footer-center ul{float:left;width:25%;padding-left:0px;}
.footer-center li{list-style-type:none;}
footer{color: #fff;}
footer ul a{color:#00aeef;font-size:15px;}
footer ul a:hover{color:#00aeef;}
footer .title a b{color:#fff;}
li.title a{color:#1B1717;text-transform:uppercase;}
li.title a:hover{color:#45545f;}
ul.footer-products li, ul.footer-nav li, ul.footer-address li{margin-bottom:10px;}
ul.footer-address li{font-size:15px;color:#fff;}
ul.footer-address li.address{line-height:1.7em;}
.footer-misc li{margin-bottom:10px;}
.footer-bottom{background:white;position:relative;float:left;width:100%;}
.footer-bottom .copyright{float:left;width:50%;padding:12px 30px 5px 30px;font-weight:400;}
.footer-bottom .made-by{float:right;width:50%;text-align:right;padding:5px 30px 5px 30px;font-weight:400;}
.footer-bottom .made-by img{vertical-align:middle;}
.order-content-container{width:100%;position:relative;float:left;}
.order-content{width:100%;max-width:1260px;margin:0 auto;padding:30px 0;color:#1B1717;}
.order-content h1{color:#00aeef;font-size:32px;}
.order-content img{float:left;}
.order-content-left{width:470px;float:left;}
.order-content-right{width:270px;float:left;margin-left:40px;}
.information_right{float:left;margin-left:20px;}
.order-content .information_right p{width:360px;font-weight:normal;}
.order-content .information-right p{padding:0 30px;color:#1B1717;font-size:17px;line-height:1.6em;font-family:'Lato', 'Arial', sans-serif;}
.order-content .information-right p b{font-weight:normal;}
.order-content .information-right p i{font-weight:normal;font-size:17px !important;}
.order-content .information-right h3{color:#1B1717;font-size:36px;margin-top:1em;padding:0 30px;}
.order-content p{margin-bottom:2px;font-size:14px;font-weight:700;width:auto;}
.order-content span p{font-size:12px;}
.order-content h3{margin-top:5px;margin-bottom:0px;color:#00aeef;font-size:30px;}
.order-content select{width:180px;display:block;height:20px;float:left;margin-bottom:10px;border-radius:0px !important;background:url('/wp-content/themes/bespoke/img/blank-rec-blue.png') rgb(239,239,239) no-repeat;background-size:20px 20px;background-position:right center;outline:none;}
.order-content input[type=submit]{float:left;display:block;margin-top:20px;width:180px;font-size:16px;color:#fff;background:#00aeef;padding:10px 20px 10px 20px;text-align:center;border:none;cursor:pointer;}
.order-left{background:#45545f;color:#fff;padding:8px 15px 8px 15px;text-align:center;font-size:14px;font-weight:700;}
.order-left:hover{background:#00aeef;}
.order-content input[type=submit]:hover{background:#45545f;}
.no-top{margin-top:0px;}
.price-guide{margin-top:30px;float:left;width:100%;}
.price_guide_title{margin-bottom:0px;}
.price-guide p.table_tab{padding:8px 7px 8px 7px;margin-bottom:0px;text-align:center;background:#45545f;color:#fff;float:left;margin-right:5px;cursor:pointer;font-size:11px;}
.price-guide p.table_tab_active{background:#00aeef;}
.price-guide table{text-align:center;border-collapse:collapse;border:solid 1px #00aeef;width:100%;}
.price-guide thead{background:#cccccc;font-size:13px;font-weight:700;border-color:#45545f;}
.price-guide thead th{padding:10px 20px 10px 20px;text-align: center;background-color:#cccccc;color:#45545f;text-transform:none;}
.price-guide table tr:last-child th { border-width: inherit;}
.price-guide tbody{background:#cccccc;font-size:13px;background:#fff;border-color:#45545f;}
.price-guide tbody tr td:first-child{background:#74848f;color:#fff;padding:5px 10px 5px 10px;width:90px;border-bottom-color:#fff;cursor:default;}
.price-guide tbody td{padding: 0.2em 1em;}
.price-guide tbody td:hover{background:#00aeef;cursor:pointer;color:#fff !important;}
.price-guide tbody td:hover>a{color:#fff;}
.price-guide tbody td a{color:#45545f;width:100%;height:36px;display:block;line-height:36px;}
.price-guide tbody td a:hover{color:#fff;}
.voucher-code{width:100%;padding:15px 15px 15px 15px;float:left;background:#00aeef;color:#fff;font-size:13px;margin-top:25px;}
.voucher-code p, .special-instructions p{width:auto;color:white;}
.voucher-code h3{margin-top:0px;}
.voucher-code input[type=button]{background:#45545f;text-decoration:none;color:#fff;padding:5px 10px 5px 10px;float:right;margin-top:20px;border:none;cursor:pointer;font-size:13px;}
.voucher-code input[type=text]{margin-left:0px;border:none;height:35px;width:100%;padding:5px;}
.special-instructions h3{margin-top:0px;}
.special-instructions{width:100%;padding:15px 15px 15px 15px;float:left;background:#45545f;color:#fff;font-size:13px;margin-top:25px;}
.special-instructions p{color:white;}
textarea{resize:none;height:140px;margin-left:0px;width:100%;padding:5px;background-color: #ededed;}
.special-instructions textarea{resize:none;height:140px;border:none;margin-left:0px;width:100%;padding:5px;}
.delivery-options{width:100%;padding:15px 15px 15px 15px;float:left;background:#efefef;color:#1B1717;font-size:13px;margin-top:25px;

	[data-background = "black"] &{
		color: #fff;
	}
}
.delivery-options h3{margin-top:0px;}
.delivery-options select{margin-left:0px;width:100%;height:35px;background:-webkit-linear-gradient(#fcfcfc, #e8e8e8);background:-o-linear-gradient(#fcfcfc, #e8e8e8);background:-moz-linear-gradient(#fcfcfc, #e8e8e8);background:linear-gradient(#fcfcfc, #e8e8e8);}
.delivery-address{width:100%;padding:15px 15px 15px 15px;float:left;background:#efefef;color:#45545f;font-size:13px;margin-top:25px;}
.delivery-address--block{border-bottom:1px solid #fff;padding:1em 0;}
.delivery-address--block[data-visible="false"] .delivery-address--block-inner{display: none;}
.delivery-address--block-inner{display:block;}
.delivery-address--notice {font-size:.9em;font-style:italic;padding:.4em 0 0;}
.edit_button{color:#00aeef;display:block;text-align:right;}
.delivery-address h3{margin-top:0px;}
.delivery-address p.indent{margin-left:23px;width:auto;}
.delivery-address input[type=text]{padding:5px;border:0;box-shadow:0;}
.delivery-address select{background:-webkit-linear-gradient(#fcfcfc, #e8e8e8);background:-o-linear-gradient(#fcfcfc, #e8e8e8);background:-moz-linear-gradient(#fcfcfc, #e8e8e8);background:linear-gradient(#fcfcfc, #e8e8e8);}
.delivery-address select, .delivery-address input[type=text]{margin-left:23px;height:35px;width:86%;margin-top:10px;}
.order-number{width:100%;padding:15px 15px 15px 15px;float:left;background:#00aeef;color:#ffffff;margin-top:1em;}
.order-number h3{margin-top:0px;}
.order-number input{margin-left:0px;height:35px;width:100%;padding:5px;border:0;box-shadow:0;}
.left_login{margin-top:20px;float:left;width:370px}
.left_login p, .right_login p{width:auto;}
.right_login{margin-top:63px;float:right;width:370px;}
.left_login h1{color:#00aeef;font-size:23px;}
.right_login .link_button{color:#fff;background:#45545f;padding:6px 20px 6px 20px;margin-right:20px;display:block;width:90%;text-align:center;margin-bottom:18px;}
.right_login .link_button:hover{color:#fff;background:#00aeef;padding:6px 20px 6px 20px;margin-right:20px;}
.customer_register{width:100%;float:left;margin-top:20px;margin-bottom: 1em;}
.customer_register.sign-up{margin-top:0;}
.customer_register .lbl-password{margin-top:14px;}
.customer_register h3{color:#00aeef;margin-top:40px;float:left;}
.customer_register .password-desc{color:#00aeef;font-size:13px;text-align:right;width:100%;float:left;display:block;}
.customer_register select{width:100%;float:left;border-radius:0px !important;background:url('/wp-content/themes/bespoke/img/blank-rec-blue.png') rgb(239,239,239) no-repeat;background-size:20px 20px;background-position:right center;outline:none;}
.customer_register input[type=text], .customer_register input[type=password]{width:100%;height:35px;float:left;border:solid 1px #929292;background:rgb(239,239,239);outline:none;padding-left:7px;display:block;}
.customer_register label{width:100%;float:left;display:block;margin-top:10px;}
.customer_register label:first-of-type{margin-top:0;}
.customer_register input[type=submit]{float:left;display:block;margin-top:1.5em;width:100%;font-size:16px;color:#fff;background:#00aeef;padding:10px 20px 10px 20px;text-align:center;border:none;cursor:pointer;transition: background-color 0.3s ease-in-out,
				color 0.3s ease-in-out;}
.customer_register input[type=submit]:hover{background-color: rgb(85, 85, 85);}
.center .default{float:left;width:280px;}
.billing_address, .delivery_address:hover, .new_address, .continue_order:hover{padding:8px 15px 8px 15px;background:#00aeef;color:#fff;font-size:13px;text-transform:uppercase;margin-right:20px;}
.billing_address:hover, .delivery_address, .new_address:hover, .continue_order{padding:8px 15px 8px 15px;background:#45545f;color:#fff;font-size:13px;text-transform:uppercase;}
.addresses{width:562px;float:left;margin-left:20px;}
.success_page{float:left;margin-top:15px;}
.success_page a{color:#fff;background:#45545f;padding:6px 20px 6px 20px;margin-right:20px;}
.success_page a:hover{color:#fff;background:#00aeef;padding:6px 20px 6px 20px;margin-right:20px;}
.success_message p, .success_message_blue p{width:100%;background-color:#66CD00;height:30px;line-height:30px;text-align:center;color:white;}
.success_message_blue p{background-color:#00aeef;}
h2.welcome_heading{text-align:center;color:#00aeef;font-size:20px}
.testimonial-center{float:left;width:780px;margin-top:15px;}
.testimonial-center h1{font-size:32px;color:#00aeef;margin-bottom:0px;}
.blog_item, .blog_item_alt, .blog_item_alt_2{float:left;width:100%;border-bottom:solid 1px #45545f;padding-bottom:20px;padding-top:20px;}
.blog_item h2, .blog_item_alt h2, .blog_item_alt_2 h2{margin-bottom:0px;color:#45545f;font-size:18px;}
.blog_item p, .blog_item_alt p, .blog_item_alt_2 p{color:#565656;width:auto;}
.blog_item a.read_more, .blog_item_alt a.read_more, .blog_item_alt_2 a.read_more{font-weight:bold;color:#00aeef;}
.blog_item a.read_more:hover, .blog_item_alt a.read_more:hover, .blog_item_alt_2 a.read_more:hover{font-weight:bold;color:#45545f;}
.blog_item span, .blog_item_alt span, .blog_item_alt_2 span{color:#565656;}
.blog_item span a, .blog_item_alt span a, .blog_item_alt_2 span a{color:#565656;}
.blog_item span a:hover, .blog_item_alt span a:hover, .blog_item_alt_2 span a:hover{color:#00aeef;}
.blog_item_alt img{float:left;margin-top:18px;}
.blog_item_alt .blog_right{float:left;width:calc(100% - 180px);margin-left:20px;}
.blog_item_alt_2 img{float:right;margin-top:18px;}
.blog_item_alt_2 .blog_left{float:left;width:calc(100% - 180px);margin-left:20px;}
.blog_left p{margin-right:20px;}
.blog_left{margin-left:0px !important;}
.sidebar_right{float:right;width:100%;padding:15px;margin-top:20px;background:#45545f;color:#fff;}
.sidebar_right h2{margin:0px;font-size:18px;}
.sidebar_right ul{padding-left:0px;}
.sidebar_right ul li{list-style-type:none;padding-bottom:9px;font-size:14px;}
.sidebar_right ul li a{color:#fff;}
.sidebar_right ul li a:hover{color:#939393;}
.sidebar_right ul li span{float:right;}
.single_blog{width:430px;float:left;padding-bottom:20px;border-bottom:solid 1px #45545f;}
.single_blog h2{font-size:16px;color:#45545f;}
.single_blog p{font-size:14px;color:#939393;}
.single_blog b{color:#45545f;}
.single_blog span{color:#939393;}
.single_blog span a{color:#939393;}
.single_blog span a:hover{color:#00aeef;}
.resources{margin-top:15px;width:760px;float:left;}
.resources p{width:51%;float:left;}
.resources a{padding:12px 15px 12px 55px;background:#45545f;color:#fff;font-size:14px;margin:0px 0px 0px 0px;float:left;background-image:url(/img/pdf_icon.png);background-repeat:no-repeat;background-position:5px 5px;background-size:23px 30px;}
.resources a:hover{padding:12px 15px 12px 55px;background:#00aeef;color:#fff;background-image:url(/img/pdf_icon.png);background-repeat:no-repeat;background-position:5px 5px;background-size:23px 30px;}
.resources_popup{background:#fff;color:#45545f;box-shadow:5px 5px 10px #ccc;width:500px;padding:10px 10px 30px 10px;float:left;}
.resources_popup h2{font-size:24px;margin-bottom:0;padding:0 10px;}
.resources_popup p{font-size:16px;line-height:1.4em;padding:0 10px;}
.resources_popup .first-name,
.resources_popup .surname{width:50%;float:left;box-sizing:border-box;padding:0 10px;}
.resources_popup .email,
.resources_popup .submit-button{width:100%;float:left;box-sizing:border-box;padding:0 10px;}
.resources_popup .email{margin-top:10px;}
.resources_popup label{width:100%;display:block;}
.resources_popup input[type=text]{width:100%;outline:none;color:#45545f;height:35px;float:left;border:solid 1px #929292;background:rgb(239,239,239);padding-left:7px;}
.resources_popup input[type=email]{width:100%;outline:none;color:#45545f;height:35px;float:left;border:solid 1px #929292;background:rgb(239,239,239);padding-left:7px;}
.resources_popup input[type=button]{float:left;border:none;color:#fff;background:#00aeef;padding:10px 15px 10px 15px;margin-top:15px;cursor:pointer;font-size:16px;width:100%;}
.resources_popup input[type=button]:hover{float:right;border:none;color:#fff;background:#45545f;padding:10px 15px 10px 15px;margin-top:15px;cursor:pointer;}
@media screen and (max-width:1080px){.resources img{width:30%;}
.resources{width:70% !important;}
}
@media screen and (max-width:1000px){.resources{margin-left:20px;}
}
@media screen and (max-width:925px){.resources{width:95% !important;}
.resources p{width:70%;}
}
@media screen and (max-width:600px){.resources img{display:none;}
.resources p{width:100%;}
}
@media screen and (max-width:1115px){.sidebar-left{margin-left:10px;}
}
@media screen and (max-width:1100px){.slider-center{width:100%;float:left;}
.digital-products, .litho-products, .products-page h1{padding-left:20px;}
.testimonials .sidebar-left{display:none;}
.testimonials .three-products{width:97%;float:left;}
.testimonials-main-content .full-testimonial p, .testimonials-main-content .right-testimonials p, .testimonials-main-content .left-testimonials p{width:90%;}
.testimonials-main-content{width:98%;}
.full-testimonial, .right-testimonials, .left-testimonial{width:98%;float:left !important;}
.testimonials-main-content img.end-quote{left:0%;}
}
@media screen and (max-width:1075px){header{width:100%;}
}
@media screen and (max-width:1080px){.slider{padding-top:30px;}
.center_responsive{width:802px;margin:0 auto;}
.homepage-banners{width:75.5%;margin:0 auto;}
.center, .testimonial-center{width:100%;}
.testimonial-center h1{width:96%;margin-left:8px;}
.blog_item, .blog_item_alt, .blog_item_alt_2{margin-left:10px;}
.about-main-content{width:65%;}
.sidebar-left{width:25%;}
.center .about-main-content p{width:98%;}
.offer1{float:none;margin:0 auto;width:100%;height:auto;}
.offer1 img{width:100%;}
.offer2{float:left;margin-top:20px;}
.offer3{margin-left:10px;float:right;margin-top:20px;}
.content-center, .popular-center, .testimonials-center, .footer-center{width:100%;float:left;}
.right{display:none;}
.left{width:94%;margin-left:2%;}
.popular-product{width:24%;}
.clear-resp{clear:both;}
.testimonials-center{padding-left:20px;}
.left-testimonial, .right-testimonial{width:100%;margin-right:0px;margin-left:0px;}
.testimonials-center p, .testimonials-center p{width:95%;}
.footer-center ul{width:50%;}
.resources{width:96%;}
}

@media screen and (max-width:1014px){.left_login, .right_login{width:96%;margin-left:10px;}
.right_login .link_button{width:300px;}
}
@media screen and (max-width:1000px){.single_blog{width:96%;margin-left:20px}
}
@media screen and (max-width:980px){.customer_register{float:left;margin-top:20px;}
}
/*
@media screen and (max-width:960px){.logo{width:100%;text-align:center;margin-bottom:20px;padding-left:0;}
.header-right p.cart{float:none;}
.header-right h3{margin-top:0px;}
.header-right .above-email{float:none;margin-top:10px;padding-bottom:5px;}
.header-right .below-email{float:none;padding-bottom:20px;}
.header-right .header-links{float:none;}
.header-right .header-links .separator{display:none;}
.logo img{padding-bottom:0px;}
.logo-img{margin-top:10px;}
.header-right{width:100%;text-align:center;padding-right:10px;}
.header-right a.blue-header-button, .header-right a.grey-header-button{float:none;}
.header-right p{text-align:center;}
.header-right p.cart{text-align:center;padding-left:0;width:100%;}
.header-right p.number{margin:0px 0px 0px 0px;}
.header-right img.email_info{float:none;margin:0 auto;}
header .search-container{box-sizing:border-box;width:50%;float:right;padding:0 30px;margin-right:0;margin-top:10px;}
header .search-container input.search-bar{width:calc(100% - 45px);border-color:#899ca9;}
.call-mob{display:block;padding-left:30px;}
.call-mob a{font-weight:bold;color:#00aeef;}
.call-mob a a:hover{color:#009ac2;}
}
*/
@media screen and (max-width:925px){.about .sidebar-left{display:none;}
.about-main-content{width:98%;}
.three-products{float:left;width:96%;}
.pop-product{width:33%;}
}
@media screen and (max-width:833px){.separator{clear:both;}
.order-content-right{margin-left:0px;}
}
@media screen and (max-width:805px){.center_responsive{width:96%;margin:0 auto;}
.offer1{float:none;margin:0 auto;width:96%;height:auto;}
.offer1 img{width:100%;}
}
@media screen and (max-width:790px){.center .checkout-summary-content p{width:auto;}
.checkout-upload-content{width:98%;}
.center .checkout-upload-content p{width:auto;}
.popular-product{
	width:49%;
	

}
}

@media screen and (max-width:787px){.testimonials-main-content img.end-quote{left:97.5%;top:0px;}
}
@media screen and (max-width:768px){nav{width:100%;}
.order-content{width:98%;}
}
@media screen and (max-width:750px){.information_right{margin-left:0px;margin-top:20px;}
.separator_product{clear:both;}

}
@media screen and (max-width:730px){.printing{width:100%;height:auto;margin-bottom:20px;text-align:center;}
.sidebar_right{display:none;}
.grey{margin-left:0px;}
.checkout-upload-content img{width:100%;}
.blog_item, .blog_item_alt, .blog_item_alt_2{width:96%;margin-left:10px;}
}
@media screen and (max-width:698px){.homepage-banners{width:96%;}
}
@media screen and (max-width:680px){.three-products{display:none;}
.right-specific-product{width:100%;}
.single-specific-product{width:100%;}
.bg .right-specific-product{width:100%;padding-left:0px;}
}
@media screen and (max-width:660px){.products-page .center p{width:98%;}
}
@media screen and (max-width:603px){.order-price-summary{float:left;width:100%;}
.basket-buttons{width:100%;}
.order-left{float:left;width:100%;margin-top:10px;margin-left:0;}
header .search-container{width:100%;float:left;}
.call-mob{width:100%;text-align:center;padding-right:30px;}

}
@media screen and (max-width:560px){.blog_item .blog_right, .blog_item_alt .blog_right, .blog_item_alt_2 .blog_right{width:96%;margin-left:0px;}
}
@media screen and (max-width:593px){.offer2{margin:0 auto;float:none;margin-top:10px;}
.offer3{margin:0 auto;float:none;margin-top:10px;}
.popular-product{width:100%;}
.about-main-content img{float:left !important;margin-left:0px !important;margin-right:10px;margin-top:10px;}
}
@media screen and (max-width:530px){.footer-bottom .copyright,
.footer-bottom .made-by{width:100%;text-align:center;}
}
@media screen and (max-width:515px){.order-content-left, .order-content-left p, .delivery-options, .delivery-address, .order-number{width:100%;}
.start-quote, .end-quote{display:none;}
.left-testimonial p.author, .right-testimonials p.author, .full-testimonial p.author{margin-left:7px;}
}
@media screen and (max-width:480px){footer{text-align:center;}
.footer-center ul{width:100%;}
.form_errors{padding-left:250px;}
.single_blog img{width:96%;display:block;margin:0 auto;}
}
@media screen and (max-width:434px){.upload-right{width:300px;}
}
@media screen and (max-width:400px){.header-right h3{margin-left:10px;}
}
@media screen and (max-width:377px){.single-specific-product img{width:94%;}
.bg .single-specific-product img{width:97%;}
.header-right img.email_info, .logo img{width:96%;}
}
@media screen and (max-width:360px){.printing img{width:98%;}
}
@media screen and (max-width:340px){.logo img{width:240px;margin:20px auto 0 auto;}
}
.ui-tooltip, .arrow:after{background:#45545f;}
.ui-tooltip{position:absolute;width:170px;height:35px;padding:10px 20px;color:white !important;font:bold 12px "Helvetica Neue", Sans-Serif;box-shadow:none !important;background:#45545f;}
.arrow{width:70px;height:16px;overflow:hidden;position:absolute;bottom:-16px;}
.arrow:after{content:"";position:absolute;left:0px;top:-20px;width:25px;height:25px;box-shadow:6px 5px 9px -9px black;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);}
.arrow.top:after{bottom:-20px;top:auto;}
.ui-helper-hidden-accessible div{display:none;}
.check-out-container{width:100%;position:relative;float:left;}
.check-out-bar {
	background-color: #171312;
	color:white;
	width: 100%;
	max-width: 1260px;
	font-size: 29px;
	margin: 0 auto;
	font-family: 'Lato', 'Arial', sans-serif;
	text-align: center;
	padding: 1em 1em 1.2em;
}
.order-within {
	margin-top:0;
	margin-bottom:0;
	font-weight:300;
	line-height: 1.2;
}
.hours, .minutes, .seconds{position:relative;}
.hours{margin-left:10px;}
.hours:after, .minutes:after, .seconds:after{position:absolute;bottom:-15px;left:0;right:0;color:#a1eaf9;font-size:15px;font-weight:400;}
.hours:after{content:'hours';}
.minutes:after{content:'mins';}
.seconds:after{content:'secs';}
.seconds{margin-right:10px;}
.date{margin-left:10px;}
@media screen and (max-width:1200px){.check-out-bar{font-size:26px;}
.hours:after, .minutes:after, .seconds:after{font-size:14px;}
}
@media screen and (max-width:1100px){.check-out-bar{font-size:24px;}
.hours:after, .minutes:after, .seconds:after{font-size:14px;}
}
@media screen and (max-width:1062px){.check-out-bar{font-size:22px;height:auto;}
.time{clear:both;}
.hours:after, .minutes:after, .seconds:after{font-size:14px;}
}
@media screen and (max-width:946px){.check-out-bar{font-size:29px;}
.hours:after, .minutes:after, .seconds:after{font-size:15px;}
.time{display:block;margin-bottom:20px;}
.date{display:block;}
}
@media screen and (max-width:450px){.check-out-bar{font-size:26px;}
}
@media screen and (max-width:400px){.check-out-bar{font-size:24px;}
}
@media screen and (max-width:370px){.check-out-bar{font-size:23px;}
.hours:after, .minutes:after, .seconds:after{font-size:14px;}
}
@media screen and (max-width:360px){.check-out-bar{font-size:22px;}
}
@media screen and (max-width:350px){.check-out-bar{font-size:20px;}
.hours:after, .minutes:after, .seconds:after{font-size:13px;}
}
.time-wrapper{word-spacing:5px;position:relative;float:left;display:inline;}
.delivery-van{margin-top:15px;}
.under{color:white;word-spacing:33px;margin-top:-5px;margin-left:15px;font-size:11px;}
.under-time{color:white;word-spacing:26px;margin-top:-5px;margin-left:7px;font-size:11px;}
.free-uk{margin-top:10px;margin-left:30px;}
.arrow-check{margin-top:-70px;width:37px;margin-left:275px;position:absolute;}
.yourOrder{font-weight:400;font-size:20px;position:relative;float:left;display:inline;}
.yourOrder-one{margin-top:-55px;font-weight:400;font-size:20px;margin-left:730px;}
.wrapper{margin-left:525px;margin-top:-60px;word-spacing:5px}
@media screen and (max-width:1012px){.yourOrder{margin-top:-51px;}
}
@media screen and (max-width:888px){.yourOrder{margin-top:13px;margin-left:29px;}
}
@media screen and (max-width:715px){.wrapper{margin-left:25px;margin-top:-4px;}
.yourOrder{margin-top:-53px;margin-left:216px;}
}
@media screen and (max-width:525px){.van-delivery img{display:none;}
.arrow-check{display:none;}
.wrapper{margin-left:174px;margin-top:-61px;}
.yourOrder{margin-top:-51px;margin-left:347px;}
}
@media screen and (max-width:487px){.breaker{display:none;}
.yourOrder{margin-top:10px;margin-left:9px;}
.under-time{color:white;word-spacing:15px;margin-top:-4px;margin-left:2px;font-size:11px;}
.wrapper{margin-left:175px;margin-top:-59px;}
}
@media screen and (max-width:414px){.wrapper{margin-left:138px;margin-top:-16px;}
.yourOrder{margin-top:10px;margin-left:9px;text-align:center;}
.under-time{color:white;word-spacing:10px;margin-top:-4px;margin-left:-7px;font-size:11px;}
}
@media screen and (max-width:375px){.wrapper{margin-left:138px;margin-top:-16px;}
.yourOrder{margin-top:10px;margin-left:9px;text-align:center;}
.under-time{color:white;word-spacing:10px;margin-top:-4px;margin-left:2px;font-size:11px;}
}
@media screen and (max-width:320px){.header-right h3{font-size:20px;}
.single-product{width:252px;text-align:center;margin-bottom:20px;margin:0 auto;}
.search-bar{margin-left:10px;margin-right:10px}
.wrapper{margin-left:0px;margin-top:-16px;text-align:center;}
.yourOrder{margin-top:10px;margin-left:9px;text-align:center;}
.under-time{color:white;word-spacing:10px;margin-top:-4px;margin-left:8px;font-size:11px;}
nav > label{cursor:pointer;-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
}

#paypal-button-container{

}

.hidden{
    display:none;
}

.hidden.error{
	text-align: left;
}

.checkoutstepsummary .general-half--pushup, .checkoutstepsummary .general-half--pushup h2{
    padding-top: 0;
}

.alert-error p{
	color: red;
	margin: 0;
}



/* =Full article
==========================================*/
.single-blog .wrap {
    clear: both;
    color: #1B1717;
    margin: 0 auto;
    padding: 0 5%;
}

.single-blog.content-center {
    float:none;
}

@media screen and (min-width:500px){
    .single-blog .wrap {
        padding: 0 10%;
    }
}
@media screen and (min-width:700px){
    .single-blog .wrap {
        padding: 0 15%;
    }
}
@media screen and (min-width:900px){
    .single-blog .wrap {
        padding: 0 20%;
    }
}

.single-blog-heading{
    color: white !important;
    font-family: 'Lato', 'Arial', sans-serif !important;
    font-size: 36px !important;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}

.article--full {
    margin-bottom: 3.75em;
}

.article--content, .article--content p, .article--content p span{
    background-color: none !important;
    color: #3a3d3e !important;
    font-family: 'Lato', 'Arial', sans-serif !important;
    font-size: 17px !important;
}

.article--content span, .article--content{
    background-color: none !important;
    line-height: 26px;
}

.article--content a{
    color: #00aeef !important;
}

.article--heading{
    font-size: 36px;
    margin-bottom: 0.7em;
}

.article--date span{
    color: #00aeef !important;
    font-size: 1.1em;
}

.content--intro {
    font-size: 1.333em;
    line-height: 1.8;
    margin-bottom: 1.75em;
}

.single-blog .article--image{
    margin: 1.75em 0;
}


.article--image img{
    width: 100%;
}

.article--footer-shares .button-wrap{
    margin-top: 3em;
}

.article--footer-shares .button-wrap a{
    background-color: #00aeef;
    color: white;
    padding: 1em;
}

/** Article Overview
*/
.archive-blog{
    clear: both;
    float: none;
    margin: 0 auto;
    max-width: 1260px;
    position: relative;
    width: 100%;
}
.archive-blog .wrap {
    color: #1B1717;
    margin: 0 auto;
    padding: 0 3%;
}
.archive-blog .article-list{
    margin: 3.75em 0 0;
}

.archive-blog .content-center {
    float:none;
}

.archive-blog .article--featured:first-of-type {
    margin-top: 0;
    margin-bottom: 4.5em;
}

.archive-blog .article--text-wrap {
    margin-bottom: 2em;
    width: 100%;
}

@media screen and (min-width:900px){

    .archive-blog .article--featured {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .archive-blog .article--text-wrap {
        display: block;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin: 0 0 2em;
        margin-right: 2em;
        max-width: 24em;
        width: 50%;
    }

}



.archive-blog .article--date {
    color: #959595;
    display: block;
    margin: 0 0 .4em;
}

.archive-blog .article--heading {
    font-size: 1.5em;
    font-weight: 400;
    line-height: 1.4;
    margin: 0 0 1em 0;
}

.archive-blog .article--featured .article--content {
    font-size: 1.125em;
    margin-bottom: 2em;
}

.archive-blog .article--featured .article--heading {
    margin-bottom: 1.5em;
}

.archive-blog .article-list .button-wrap a{
    background-color: #00aeef;
    color: white;
    padding: 1em;
}

.archive-blog a.article--image, .archive-blog a.article--image:visited {
    opacity: 1;
    -webkit-transition: opacity .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
}

.archive-blog .article--date--day {
    border-right: 1px solid #959595;
    color: #f1593a;
    display: inline-block;
    font-size: 1.8em;
    font-weight: 700;
    line-height: 1;
    margin: 0 .3em 0 0;
    padding: 0 .3em 0 0;
}

.archive-blog .article--date--month {
    color: #959595;
    font-size: 1.125em;
    position: relative;
}

.archive-blog .article--heading {
    font-size: 2em;
    font-weight: 400;
    line-height: 1.1;
    margin: 0;
}

.archive-blog .article--heading a{
    color: #1B1717;
    font-size: 0.8em;
}

.archive-blog .archive-blog .article--image {
    display: block;
    margin: 0 0 1em;
    max-width: 100%;
}

.archive-blog .article--listed {
    margin: 0 0 4.5em;
}

.article--listed .article--date{
    margin-top: 1em;
}



/* =Product Grid Page Template
==========================================*/
.product--grid--container {
	padding: 0 2em;
	
	&[data-pad="toppad"] {
		padding-top: 4em;
	}
}

@media only screen and (min-width: 1300px) {

	.product--grid--container {
		padding: 0;
	}

}

.product--grid--heading {
	font-size: 1.75em;
	margin: 0 0 1em;
}

.product--grid {
	display: grid;
	grid-gap: 2.125em;
	grid-template-columns: 1fr;
}

@media only screen and (min-width: 600px) {

	.product--grid {
		grid-template-columns: repeat(2, 1fr);
	}

}

@media only screen and (min-width: 800px) {

	.product--grid {
		grid-template-columns: repeat(3, 1fr);
	}
	
}

@media only screen and (min-width: 1000px) {

	.product--grid {
		grid-template-columns: repeat(4, 1fr);
	}
	
}

.product--overviews {
	background-color: #1B1717;
	color: #fff;
	padding: 2em;
}

.product--overview {
	margin: 3em 0;
}

.product--overview:first-child {
	margin-top: 0;
}

.product--overview:last-child {
	margin-bottom: 0;
}

@media only screen and (min-width: 900px) {

	.product--overviews {
		display: flex;
		justify-content: space-between;
	}

	.product--overview {
		margin: 0;
		width: 48%;
	}

}

.product--overview--heading {
	color: #fff;
	font-size: 1.5em;
	margin: 0 0 1em;
}

.product--overview--text {
	color: #fff;
	font-size: 1.063em;
}




/* =Listed Product Module
==========================================*/
.product--listed,
.product--listed:visited {
	color: #1B1717;
	display: block;
	margin: 0 0 1em;
}

.product--listed:hover .product--listed--image img,
.product--listed:active .product--listed--image img {
	opacity: .7;
}

.product--listed--image {
	background-color: #1B1717;
	display: block;
	margin: 0 0 1.4em;
}

.product--listed--image img {
	display: block;
	opacity: 1;
	transition: opacity .25s ease-in-out;
}

.product--listed--inner {

}

.product--listed--heading {
	color: #1B1717;
	font-size: 1.5em;
	font-weight: 700;
	margin: 0;
}

.product--listed--text {
	font-size: 1.125em;
	margin: .5em 0 0;
}

.product--listed--text span {
	color: #00aeef;
	font-size: 1.222em;
	font-weight: 700;
}



/* =Product Specification Sidebar
==========================================*/
.spec-actions {
	align-items: center;
	background-color: #1B1717;
	display: block;
	display: flex;
	justify-content: space-between;
	padding: 1em;
}

.spec-actions--price {
	color: #fff;
	font-size: 1.875em;
	line-height: 1.2;
	margin: 0;
}

.spec-actions--price span {
	color: #00aeef;
	display: block;
	font-size: 0.433em;
}

.spec-builder {
	padding: 1.4em 1em;
}

.spec-builder--inner {

}

.spec-builder--heading {
	color: #00aeef;
	display: block;
	font-size: 1.25em;
	margin: 0 0 .6em;
}

.spec-builder .f-field {
	display: block;
	margin: 1em 0;
}

.spec-builder .f-field:last-child {
	/* margin: 0; */
}

.spec-builder .f-field label {
	display: block;
	font-size: .813em;
	margin: 0 0 .5em;
}

.spec-builder .f-field select {
	display: block;
	width: 100%;
}



/* =jQuery UI Select Menus
==========================================*/
.ui-selectmenu-button.ui-state-default {
	background: #e5e5e5;
	border: none;
	border-radius: 0;
	box-shadow: none;
	color: #1B1717;
	display: block;
	font-size: .813em;
	font-weight: 700;
	margin: 0;
	padding: 1em;
	position: relative;
	vertical-align: middle;
	width: 100% !important;

	transition: background .25s ease-in-out;

}

.ui-selectmenu-button.ui-state-default span.ui-icon {
	background: #00aeef;
	background-image: url('../img/svgs/icon-arrow-down.svg');
	background-position: center;
	background-repeat: no-repeat;
	height: 100%;
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 2.4em;
}

.ui-selectmenu-button.ui-state-default:hover span.ui-icon {
	background-color: #00aeef;
}

.ui-selectmenu-button.ui-state-default span.ui-selectmenu-text {
	padding: 0;
}

/* Dropdown */
.ui-selectmenu-menu {
	background: #EDEDED;
	border: none;
	box-shadow: none;
	color: #1B1717;
	margin: 0;
	padding: 0;
}

.ui-selectmenu-menu .ui-menu {
	border: none;
	border-radius: 0;
	list-style: none;
	margin: 0;
	max-height: 400px;
	padding: 0;

}

.ui-selectmenu-menu .ui-menu > li {
	margin: 0;
}

.ui-selectmenu-menu .ui-menu .ui-menu-item {
	background: #e5e5e5;
	border: none;
	color: #1B1717;
	font-size: .813em;
	font-weight: 700;
	margin: 0;
	padding: .75em 3em .75em 1em;
}

.ui-selectmenu-menu .ui-menu .ui-menu-item-wrapper {
}

.ui-selectmenu-menu .ui-menu .ui-menu-item.ui-state-active,
.ui-selectmenu-menu .ui-menu .ui-menu-item.ui-state-focus {
	background: #00aeef;
	border: none;
	border-radius: 0;
	color: #fff;
	font-weight: 700;
	margin: 0;
}

.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active {
	border-color: transparent;
}



/* =Notification Content Box
==========================================*/
.notice-box {
	background-color: #EDEDED;
	margin: 3em 0;
	padding: 1.4em;
}

.notice-box p {
	font-size: 1.063em;
	margin: .2em 0;
}

.notice-box p a,
.notice-box p a:visited {
	color: #1B1717;
	text-decoration: none;
}

.notice-box p a:hover,
.notice-box p a:focus {
	color: #00aeef;
}



/* =Template download lists
==========================================*/
.download-templates {

}

.download-templates--item {
	border: 1px solid #EDEDED;
	border-width: 0 0 1px;
	display: block;
	display: flex;
	justify-content: space-between;
	padding: 1em 0;
}

.download-templates--item p {
	font-size: 1.063em;
	margin: 0 1em 0 0;
}

.download-templates--item:last-child {
	border-width: 0;
}

.list--download-templates {
	align-items: center;
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	justify-content: flex-end;
	list-style: none;
	margin: 0;
	padding: 0;
}

.list--download-templates li {
	display: block;
	margin: 0 0 0 .4em;
}

.list--download-templates li a,
.list--download-templates li a:visited {
	display: block;
	position: relative;
	top: 0;

	transition: top .25s ease-in-out;
}

.list--download-templates li a:hover,
.list--download-templates li a:focus {
	top: -4px;
}



/* =Container for content and sidebar
==========================================*/
.container--with-sidebar {
	display: block;
}

.container--with-sidebar[data-sidebar="sticky"] {

}

.container--with-sidebar .content {

}

.container--with-sidebar .content-sidebar {
	display: block;
	margin: 0 0;
}

@media only screen and (min-width: 1000px) {

	.container--with-sidebar {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
		position: relative;
	}
	
	.container--with-sidebar .content {
		flex-grow: 0;
		flex-shrink: 0;
		order: 1;
		width: 60%;
	}
	
	.container--with-sidebar .content-sidebar {
		flex-grow: 0;
		flex-shrink: 0;
		margin: 0;
		max-width: 26.875em;
		order: 2;
		position: relative;
		right: 0;
		top: 0;
		width: 35%;
	}

	.container--with-sidebar .content-sidebar[data-status="set"] {
		bottom: 0;
		position: absolute;
		right: 0;
		top: auto;
	}

	.container--with-sidebar .content-sidebar[data-status="sticky"] {
		position: fixed;
		right: 0;
		top: 1em;
	}

}



