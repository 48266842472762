/* =Colour Variables
==========================================*/
$alert-yellow:			#ebe16f;
$alert-red:				#fbe3e4;
$alert-green:			#e6efc2;
$alert-blue:			#d5edf8;

$black:					#000;
$alt-black:				#1B1717;
$white:					#fff;
$grey:					#e5e5e5;
$lgrey:                 #F5F5F5;  

$text-color:			#1B1717;

$brand-primary:			#00AEEF;
$brand-secondary:		#eceee5;
$brand-tertiary:		#eceee5;

$link-color:        	$brand-primary;
$link-hover:        	darken($link-color, 9%);


/* =Social colours
==========================================*/
$social-facebook:		#4d70a8;
$social-twitter:		#01baf2;
$social-google:			#e3401d;
$social-linkedin:		#1687b1;


/* =Wrap Width
==========================================*/
$wrap-restrict:			78.750em;
$wrap-break:			$wrap-restrict + 3em;


/* =Strip Padding
==========================================*/
$strip-padding:			2.125em;


/* =Site Transitions
==========================================*/
$transition:			.25s;
$easing: 				ease-in-out;


/* =Radius
==========================================*/
$radius:				.4em;


/* =Font Families
==========================================*/
// font stacks
$sans-serif:        	"Helvetica Neue", Helvetica, Arial, sans-serif;
$serif:             	"Georgia", Cambria, Times New Roman, Times, serif;

$fancy-sans:			'Lato', $sans-serif;
$fancy-serif:			$serif;


/* =Font weights in use from Typekit or Google Fonts
==========================================*/
$light:					100;
$regular:				400;
$semi: 					500;
$italic:				400;
$bold:					700;
$extra-black:           800;


/* =Theme url
==========================================*/
$url:					'/wp-content/themes/cargobase-by-cargo/library/';
