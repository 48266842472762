@import 'jqueryui';

/* =Input Styling
==========================================*/
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
select,
textarea,
.field {
	border: 0;
	box-shadow: none;
	display: block;
	font-size: 1em;
	margin: 0;
	padding: .8em 1em;
	vertical-align: middle;
	width: 100%;

	&:hover,
	&:focus,
	&:active {

	}

	&.error,
	&.is-invalid {
		border-color: $alert-red;
		background-color: $white;
		background-position: 99% center;
		background-repeat: no-repeat;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NDM0NDREQkYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NDM0NDREQ0YwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ3ODRGRkE2RjA0QTExRTI5MjhERkZBMTMzMDYwNzI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY0MzQ0NERBRjA0QjExRTI5MjhERkZBMTMzMDYwNzI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+U8iT5wAAAedJREFUeNqk1U9I02Ecx/HtV3aIFAc1hcRDUoGXRAq0oNLA2CrsEFmHbikZu0iQYIFGYAiegkCpLipBxPpDEBMUzB0EhSG2LhG7hMR2GviPgUTvB57Bw8P3+U23B16HPX8+e/b8nt93wezZSMCnhXETF3AcB5BDCnH8Dq98ExcGHcFn8Ah3cdDni+fxnPDv9oAnTB7CKu6VCFXtChZy56LxUjt+jfuB8toSOth9wd7xWAWhqrUjYR/FRTwWJm+iIPT/w7bQf5ljiZnBg45dtKFX6H+LU8gIY8OEV6vgTkStwXWE8BPTGDHGPqNPz2mCfSOOYkA99TvCt1bhGPL68zMcwmncMuape10jrI+q4BbHi/FLn31S9z2x5tRhTc+1W506ipM+T3oRD4X+8+qtc4SqFvL0z/Fr14S+Szjis8bz9Lvvaq8cwS/wwGfdlqfPSWqTiFlX77o13u9Ym1PBs8JAytpRoy44X9Ft9E/gvbA+rYKn8NcaaMVc8UHgBw4b9/iqUQZ6hOAJFbyDcUflmsEX4a6+wTtHGfhAIUqa1U29Zc2BytouThD8x6xuN5CtMPi2CrXLZkZf/HyZoRFCP7n+QVR4PV7uI/AjGghN7OU/r1ilnqILtfpNC+o6vIFljBKYlhb/F2AAgaBsWR5wRiIAAAAASUVORK5CYII=);
		outline-color: $alert-red;
	}

	&.success,
	&.is-valid {
		border-color: $alert-green;
		background-color: $white;
		background-position: 99% center;
		background-repeat: no-repeat;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NDM0NDRERkYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NDM0NDRFMEYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY0MzQ0NERERjA0QjExRTI5MjhERkZBMTMzMDYwNzI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY0MzQ0NERFRjA0QjExRTI5MjhERkZBMTMzMDYwNzI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+7olkTQAAAfhJREFUeNqklU9oE0EUhzdroWjw0tBeWlDxkEaIp55TsCU9VKIgCrHBelA8CQ1Kr1WPbZrQ3gqtQqvGqxpQc2jBk6BIIaAGD4qNCKURpDSKiPi98gLDsJt//uBjsztvfnk7895sIPAw6/joGMThFJyAXn2+A+9gA57/TaY/eU0OeBgfhGm4DiGnsb7DAszxBz/NAdcKjMJLuNWCqagHbsscN5+L+hmH4QkMOe1L5jzFfNA2PgT34ajTuY7AGuZB0/hmh5m+gS0r8xv1zRvg+gGCHZiOwnF4DP3iB3sQkYxPd2C6CWfhB9Xwlus5+K1j4jXuaq3a+gM1H9OPcAa+7q9lPidZJqHbiIm7Wg22rsEI7FrPSzAMX/T+ADyAKSsu7Fr1KplehLvwCs5DvfBf65p+MypqRbO1FXK9utH4/QKuaqYTsG3E3INJv00Q46px3+XxanJ/Ute2/vqP4FKDza2KcdljIKdnhS0xXYULTaqmLMZFn8FFSFtvt6x70ExFCS5oUXspq2ssa7oEl1swFa+CGFdgtkHgPDyDKy02zyxNU6lXRUZb1EuHYayNNs+Yh5B0WQo+/8fpJnNTZFuzz2OpjoQ2QruSOQlMy35fEGmEGMxY9e1brxobw7TkWA1h6xfckUPb+JhGoE/Hpfvew7qUld/H9J8AAwDpw3WYrxcZ3QAAAABJRU5ErkJggg==);
		color: $alert-green;
		outline-color: $alert-green;
	}

	&[disabled],
	&.is-disabled {
		cursor: not-allowed;
		opacity: .6;
		&:focus,
		&:active {
			background-color: $alert-blue;
		}
	}

}

input[type="submit"] {

}

textarea {
	max-width: 100%;
	min-height: 120px;
	line-height: 1.5em;
}

select {
	-webkit-appearance: none;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAHCAYAAADXhRcnAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpEOEZCMjYxMEYwNUUxMUUyOTI4REZGQTEzMzA2MDcyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpEOEZCMjYxMUYwNUUxMUUyOTI4REZGQTEzMzA2MDcyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQ4RkIyNjBFRjA1RTExRTI5MjhERkZBMTMzMDYwNzI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkQ4RkIyNjBGRjA1RTExRTI5MjhERkZBMTMzMDYwNzI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Vxkp9gAAAI9JREFUeNpidHFxucHAwKAOxE+AmJmBMPgLxDJAfJMFSKwD4kqoAClgA+P///8ZXF1dPaCGcBKh6QcQB+3evXs7WDMIAA2QB1I7gFgDj0aQFz2BGh+AOEwwUaDAQyBlCMR7cGjcC5KHaQQBuM3IAOiKTiBVhiTUDdRUhq4Oq2aoAelAahIQ5wM1zsCmBiDAADhYMJXVZ9u9AAAAAElFTkSuQmCC);
	background-repeat: no-repeat;
	background-position: 97.5% center;
}


/* =Form Wraps
==========================================*/
.f-field {
	display: block;
	margin: 0 0 1em;
	position: relative;
}


/* =Contact Form 7
==========================================*/
.wpcf7 {
	overflow: hidden;
	position: relative;
}

.screen-reader-response {
	height: 1px;
	left: -100%;
	position: absolute;
	width: 1px;
}

.wpcf7-form-control-wrap {
	display: block;
	position: relative;
}

.wpcf7-not-valid-tip {
	@extend .alert-error;
	border-radius: 0 0 $radius $radius;
	display: block;
}

.wpcf7-validation-errors {
	@extend .alert-error;
	display: block;
	margin-top: 2em;
}

.wpcf7-mail-sent-ng {
	@extend .alert-help;
	display: block;
	margin-top: 2em;
}

.wpcf7-mail-sent-ok {
	@extend .alert-success;
	display: block;
	margin-top: 2em;
}



/* =jQuery UI Select Menus
==========================================*/
.ui-selectmenu-button.ui-button {
	background-color: $brand-primary;
	border: 4px solid darken($brand-primary, 10%);
	border-radius: 0;
	box-shadow: 0 0 1px darken($brand-primary, 10%);
	color: #9876b9;
	display: block;
	font-size: 1.25em;
	font-weight: $bold;
	margin: 0;
	padding: .6em 1em;
	position: relative;
	vertical-align: middle;
	width: 100%;

	transition: border-color $transition $easing;

	.ui-selectmenu-icon {
		background-color: $white;
		background-image: url('../images/svgs/icon-arrow-down.svg');
		background-position: center;
		height: 100%;
		right: 0;
		position: absolute;
		top: 0;
		width: 2.4em;

		.svg & {
			background-size: 17px 9px;
		}

		.no-svg & {
			background-image: url('../images/icon-arrow-down.png');
		}

	}

	.ui-selectmenu-text {

	}

	.disabled & {
		background-color: #c4dfa9;
	}

	&:hover,
	&:focus {
		border-color: $white;

	}

	&[aria-expanded="true"] {

		.ui-selectmenu-icon {
			transform: rotate(180deg);
		}

	}

}

.ui-selectmenu-menu {
	background-color: $white;
	border: none;
	box-shadow: none;
	color: $brand-primary;
	font-size: 1em;
	margin: 0;
	padding: 0;

	.ui-menu {
		border: none;
		border-radius: 0;
		list-style: none;
		margin: 0;
		max-height: 400px;
		padding: 0;

		> li {
			margin: 0;
		}

	}

	.ui-menu-item {
		font-weight: $bold;
	}

	.ui-menu-item-wrapper {
		background-color: $white;
		border: none;
		color: $text-color;
		font-weight: $bold;
		padding: .6em .8em;

		&.ui-state-active,
		&.ui-state-focus {
			background-color: $brand-primary;
			border: none;
			border-radius: 0;
			color: $white;
			font-weight: $bold;
			margin: 0;
		}

	}

}

.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active {
	border-color: transparent;
}


/* =Delivery page address form
==========================================*/


.delivery-form--container{
	background-color: $white;
	padding: 1em;
	width: 100%;
	
	h3{
		color: $brand-primary;
		margin: 0;

	}
	
	a{
		color: $brand-primary;
		font-size: 0.813em;
		font-weight: $bold;
		text-decoration: underline;
		
		&:hover,
		&:focus{
			text-decoration: none;
			color: $link-hover;
		}
	}
}

.delivery-options{
	background-color: $white;
	margin: 0;
	padding: 0;
	float: none;
	
	.field-wrap{
		padding-top: 1em;
		
		input{
			background-color: $grey;
		}
	}
	
	label{
		font-size: 13px;
		font-weight: $bold;
		
	}
	
	input, textarea{
		background-color: $grey;
	}
	
	[data-background="black"] &{
		background-color: $black;
	}
}

.delivery-options--container{
	
}

.form--button{
	background-color: $brand-primary;
}

.form-section{
	padding: 1em 0;
	
	label{
		display: block;
		padding-bottom: 0.5em;
		
		p{
			font-size: 13px;
		}
	}
	
	input{
		background-color: $grey;
	}
	
	&.ruled{
		border-bottom: 1px solid $grey;
	}
	
	select {
		background-color: $grey;
		background-image: url('/img/svgs/icon-select-arrow-down.svg');
		background-position: 100%;
		background-repeat: no-repeat;
		background-size: 44px;
		cursor: pointer;	
		font-size: 13px;
		height: 42px;

	}
}

.order-number{
	float: none;
	
	h3{
		margin: 0;
	}
	
	p{
		color: $white;
		font-size: 0.813em;
		padding: 0;
		margin: 0.5em 0;
	}
}


.form-section-main--content {
	height: 22px;
	overflow: hidden;

	.head{
		padding-bottom: 1em;
	}
	&.myAddress{
		&.active{
			height: 100%
		}
	}
	
	&.newAddress{
		&.active{
			height: 100%
		}
	}
	
	&.addressBook{
		&.active{
			height: 100%
		}
	}

	&_success{
		height: auto;
		width: 100%;
	}
	
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
	background: white;
	border: 1px solid black;
  }

  // Box hover
  &:hover + label:before {
    background: $brand-primary;
  }
  
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: $brand-primary;
  }
  
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
	content: '';
	background-image:  url('../img/svgs/icon-checkmark.svg');
    position: absolute;
    left: 3px;
    top: 7px;
	background-repeat: no-repeat;
    width: 100%;
    height: 100%;

  }
}


.save-add--button{
	background-color: $brand-primary !important;
	border: none;
	color:	#fff;
	font-size: 13px;
	font-weight: 700;
	padding: 1.25em 1em;
	
	transition: background-color $transition $easing,
				color $transition $easing;
				
	width: 100%;
	
	&:hover,
	&:focus{
		cursor: pointer;
		background-color: $link-hover !important;
	}
	
}


.voucher--button{
	font-size: 13px;
	width: 100%;
	padding: 1.2em 1em;
	border: none;
	color:	#fff;
	margin-top: 1em;
	background-color: $brand-primary !important;
	
	transition: background-color $transition $easing,
				color $transition $easing;
	
	&:hover,
	&:focus {
		background-color: $link-hover;
		color: $white !important;
		text-decoration: none;
	}

	&.disabled{
		background-color: #CCCCCC !important;

		&:hover,
		&:focus{
			cursor: pointer;
			background-color: #BBBBBB !important;
		}
	}

}

@import 'form-search';


@media only screen and (min-width: 700px) {

	@import 'forms_700';

}

@media only screen and (min-width: 850px) {

	@import 'forms_1150';

}

