/*

	url variable usage example:

		background-image: url('#{$url}/images/...');

*/

.logo,
.logo:visited {
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
	width: 150px;
	padding-top: 0.5em;

	img {
		display: block;
		margin: 0;
	}

}

.mast {
	padding: 1em 0;
}

.mast--telephone {
	color: $brand-primary;
	display: none;
	font-weight: bold;
	margin: 1em 0;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	width: 100%;
	
	a,
	a:visited {
		color: #00aeef;
		
		&:hover,
		&:focus {
			color: $link-hover;
		}

	}

}

.mast--basket,
.mast--basket:visited {
	align-items: center;
	background-color: #EDEDED;
	color: $text-color;
	display: flex;
	justify-content: center;
	font-weight: bold;
	margin: 1.5em 0 .2em;
	padding: .8em 1em;
	width: 100%;

	transition: background-color $transition $easing;

	> span {
		color: #00aeef;
		text-transform: uppercase;
		font-size: .75em;
		padding-right: .6em;
	}

	&:hover,
	&:focus {
		background-color: darken(#EDEDED, 10%);
	}

}

.mast--basket--detail {
	font-size: 0.813em;
	line-height: 1;
	margin: 0;
}

.mast--basket--icon {
	display: block;
	margin-right: .4em;

	svg {
		display: block;
		fill: #00aeef;
	}

}

.mast--actions {

	.button-wrap {
		margin: .2em 0;
		width: 100%;
		
		a,
		a:visited {
			display: block;
		}

	}

}

.mast--logged-in {
	display: block;
	font-size: .813em;
	margin: 1em 0;
	text-align: right;
	text-transform: uppercase;
	width: 100%;
}


.mast-inner{
	display: flex;
	justify-content: space-between;
}

.customer-account-buttons--container{
	display: none;
}


/* =Post Header
==========================================*/

.post--head{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 0 1.5em;
	
	.new_address{
		display: flex;
		align-items: center;
		padding: 11px 25px;
		margin: 1em 0 0;
		justify-content: center;
	}
	
	.order-left{
		height: auto;
	}
	
}

@media only screen and (min-width: 31.25em) {

	@import 'mast_500';

}

@media only screen and (min-width: 37.5em) {

	@import 'mast_600';

}

@media only screen and (min-width: 43.750em) {

	@import 'mast_700';

}

@media only screen and (min-width: 53.125em) {

	@import 'mast_850';

}

@media only screen and (min-width: 75em) {

	@import 'mast_1200';

}