.product-info--container-mobile{
    display: none;
}


.prod-details--head{
    
    .head-container{
        display: flex;
    }
    
}

.prod-details--container{
    
    .prod-details--child{
        
        .product--info{
            widtH: 60%;
        }
        
        .product-info--container-desktop{
            display: flex;
        }
    }
}


.production--notice-desktop{
    display: block;
    
}

.prod-details--container{
    
    .prod-details--child {
        
        .product--quantity{
            justify-content: center;
            width: 33%;
            padding-top: 1.5em;
                
        }
        
        .product--total{
            width: 33%;
            justify-content: center;
            padding-top: 1.5em;
        }
        
        .remove{
            width: 33%;
            margin: 0;
            justify-content: center;
            align-items: flex-start;
            padding-top: 1.9em;
        }
    }
    
    
} 

.faq-child--wrap{
    width: 33%;
}

.popular-center{width:100%;max-width:1260px;padding:20px 0px 30px 0px;margin: 0 auto;height:auto;position:relative;background:#ffffff;overflow:auto;}


.general-center{
    
    
	[data-background="lgrey"] &{
		display: flex;
		justify-content: space-between;
        flex-wrap: nowrap;
    
    }
    
    &.payment {
        flex-wrap: wrap !important;
    }
    
}

.general-half{

	[data-background="lgrey"] &{
		background-color: $white;
		margin: 2em 0;
		padding: 1em ;
        width: 49%;
        
        &:first-child{
			margin-top: 2em;
		}

	}

}

.basket-timeline--wrap{
    padding: 0;
    
    &.complete-order{
		justify-content: flex-end;
	}
}


/* =Address Container
==========================================*/

.address--container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    
}


.address--child {
    margin-bottom: 1em;
    width: 49%;
    
    @media only screen and (min-width: 65.625em) {
        width: 32.5%;

    }
    
    .payment &{
            
        width: 100%;     
        
    }

}