.form-section{
    display: flex;
    
    &.column{
        flex-direction: column;
        
        
        .field-wrap{
            
            label{
                margin-top: 0;
            
            }
            
        }
        
       
    }
}

.check-box--container{
    width: 5%;
    
}

.edit-link--container{
    text-align: right;
    width: 20%;
}

.form-section-main--content{
    widtH: 75%;
    padding: 0 2em 0 1em;
    
    p{
        margin-top: 0;
    }
    
}
