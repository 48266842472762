.banner {
	background-color:#00aeef;
	
}

.banner--content {
	background-color: $alt-black;
	color: #fff;
	min-height: 260px;
	padding: 2em;
}

.banner--content.bg-image {
	background-position: right;
	background-repeat: no-repeat;
	background-size: cover;
}

.banner--content .content--heading {
	color: #fff;
	margin: .25em 0 .8em;
}

.banner--content.bg-image .content--heading {
	color: #000000;
}

.banner--content.bg-image .content--heading-reversed {
	color: #ffffff;
}

.banner--content--text {

}

.banner--content--text p:first-child {
	margin-top: 0;
}

.banner--content--text p:last-child {
	margin-bottom: 0;
}

.banner--promo {
	color: #fff;
	padding: 3.875em 2em 1.875em;
	position: relative;
	text-align: center;
	width: 100%;
}

.banner--promo:after {
	background-color: #00aeef;
	content: '';
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.banner--promo--inner {
	position: relative;
	z-index: 2;
}

.banner--promo p {
	line-height: 1;
	margin: 0;
	text-transform: uppercase;
}

.banner--promo .banner--promo--lrg {
	font-size: 4.750em;
	font-weight: 700;
}

.banner--promo .banner--promo--med {
	font-size: 1.875em;
	margin: .4em 0;
	
	&.mobile--mini {
		font-size: 0.7em;
		display: inline;
	}
	
}

.banner--promo .banner--promo--reg {
	font-size: 1.375em;
	margin: .2em 0;
	
	&.mobile--mini {
		font-size: 0.7em;
		display: inline;
	}
}

.banner--promo .banner--promo--mini {
	font-size: 1em;
	margin-top: 2em;
	text-transform: none;
	
}



/* =Slide Banners
==========================================*/
.banner-slider {
	margin: 0 auto;
	max-width: $wrap-restrict;

	.button-wrap {
		margin: 2em 0;

		a,
		a:visited {
			min-width: 12em;
		}

	}

	.flex-control-nav {
		display: block;
		bottom: 1em;

		li {

			a,
			a:visited {
				border-radius: 100%;
				height: 14px;
				width: 14px;

				&:hover,
				&:focus {
					background-color: $black;
				}

				&.flex-active {
					background-color: $black;
				}

			}

		}

	}

}

.banner--slide {
	background-color: $black;
	color: $white;
	display: block;
	padding: 2em;
	position: relative;

	p {
		color: $white;
		margin: 0;
	}

}

.banner--slide--image {
	display: none !important;
	
	img {
		display: block;
		min-width: 0;
		max-width: 100%;
		width: 100%;
	}

}

.banner--slide--logo {
	bottom: 0;
	display: none;
	position: absolute;
	right: 2em;

	img {
		display: block;
	}

}

.banner--slide--inner {
	display: block;
}

.banner--slide--heading {
	font-size: 2.6em;
	font-weight: $bold;
	line-height: 1.2;
}

.banner--slide--promo {
	margin: 1em 0;
}

.banner--slide--promo--small {
	font-size: 1.063em;
	text-transform: uppercase;
}

.banner--slide--promo--lrg {
	font-size: 2em;
	font-weight: $bold;
	line-height: 1.2;
}

.banner--slide--content {
	font-size: 1.25em;
	margin: 1em 0;
	display: none;
	line-height: 1.3;

}

.banner--slide--available {
	margin: 1em 0;
	font-size: 20px;
	line-height: 1.3;

	> p {
		font-size: 1.25em;
	}

	.banner--slide--subhead {
		color: $brand-primary;
		font-size: .875em;
		text-transform: uppercase;
	}

}


/* =Product Banner
==========================================*/

.title--wrap{
	background-color: $alt-black;
	color: $white;
	padding: 0.75em 1em;

	.breadcrumb{
		margin: 0;
	}

	h1{
		color: $white;
		margin-top:0;
	}
}

.graphic--container{
	align-items: center;
	display: flex;
	justify-content: center;

	svg{
		margin-right: 1em;
		
	}
}


/* =Chekckout bar
==========================================*/

.check-out-bar {
	background-color: $grey;
}

.order-within {
	color: $black;
	font-size: 1em;
	
	.time {
		font-size: 1.125em;
		
	}
	
}

.hours::after, .minutes::after, .seconds::after{
	color: $brand-primary
	
}


/* =Sorting
==========================================*/

.sort-by--container{
	display: none;
	justify-content: flex-start;
	
	p{
		margin-right: 2em;
		margin-top: 0;
		margin-bottom: 0.2em;
	}
	
	label{
		margin-right: 2em;
		padding-left: 0.5em;
	}
	
	.sorting--filter{
		align-items: center;
		display: flex;

	}
}

.sort-by--container-mobile{
	display: flex;
	justify-content: flex-start;
	
	p{
		margin-bottom: 0.2em;
		margin-right: 2em;
		margin-top: 1em;

	}
	
	label{
		margin-right: 2em;
		padding-left: 0.5em;
		
	}
	
	.sorting--filter{
		align-items: center;
		display: flex;
		padding-top: 1em;
		
	}
	
}


@media only screen and (min-width: 43.75em) {

	@import 'banners_700';

}

@media only screen and (min-width: 56.250em) {

	@import 'banners_900';

}

