table {
	border-collapse: collapse;
	margin: 2em 0;
	width: 100%;

	th,
	td {
		padding: 1.2em 1em;
	}

	th {
		background-color: #00aeef;
		font-family: $fancy-sans;
		color: $brand-secondary;
		font-size: 1em;
		text-align: left;
		text-transform: uppercase;
	}

	td {
		font-size: 1em;
	}

	tr {

		td,
		th {
			// border-bottom: 2px solid darken($brand-primary, 2%);
		}

		&:last-child {

			td,
			th {
				
			}

		}

		&:nth-child(odd){
			background-color: lighten($brand-tertiary, 10%);
		}

	}

}

/* =Cost Matrix tables
==========================================*/
.cost-matrix {
	display: block;
}

@media only screen and (max-width: 800px) {

	.cost-matrix {
		display: none;
	}

}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {

	.cost-matrix {
		display: none;
	}

}

.cost-matrix--tabs {
	align-items: stretch;
	display: flex;
	justify-content: flex-start;
}

.cost-matrix--tabs a,
.cost-matrix--tabs a:visited {
	background-color: #1B1717;
	border: 2px solid #fff;
	border-width: 0 2px 0.5px 0;
	color: #fff;
	display: block;
	padding: .75em 1em;
	text-align: center;
	width: 13.8em;

	transition: background-color .25s ease-in-out;
}

.cost-matrix--tabs a:hover,
.cost-matrix--tabs a:focus {
	background-color: #00aeef;
}

.cost-matrix--tabs a span {
	font-size: .813em;
}

.cost-matrix--tabs a[data-active="true"] {
	background-color: #00aeef;
}

.cost-matrix--tabs a[data-active="false"] {
	
}
							
.cost-matrix--content {

}

.cost-matrix--content--tab {
	display: none;
	overflow: hidden;
	overflow-x: scroll;
}

.cost-matrix--content--tab[data-active="true"] {
	display: block;
}

.table--matrix {
	border-spacing: 0;
	margin: 0;
}

.table--matrix th,
.table--matrix td {
	border: 2px solid #fff;
	border-top: 1.5px solid white;
	font-size: .813em;
	padding: 0;
}

.table--matrix th:first-child {
	background-color: #00aeef;
}

.table--matrix th:first-child,
.table--matrix td:first-child {
	border-left-width: 0;
	left: 0;
	position: sticky;
	top: 0;
	border-right: 0.5px solid white;
	border-top: 0.5px solid white;
	display: block;
}

.table--matrix th {
	color: #fff;
}

.table--matrix th span,
.table--matrix td span,
.table--matrix td a,
.table--matrix td a:visited {
	display: block;
	padding: .6em;
}

.table--matrix thead {

}

.table--matrix thead tr {
	background-color: #00aeef;
}

.table--matrix th {
	
}

.table--matrix tbody {
	
}

.table--matrix tbody tr {
	background-color: #EDEDED;
}

.table--matrix td {
	
}

.table--matrix td:first-child {
	white-space: nowrap;
}

.table--matrix td:first-child span {
	background-color: #CCCCCC;
}

.table--matrix td span,
.table--matrix td a,
.table--matrix td a:visited {
	background-color: #EDEDED;
	color: #1B1717;

	transition: background-color .25s ease-in-out;
}

.table--matrix td a:hover,
.table--matrix td a:focus {
	background-color: #00aeef;
	color: #1B1717;
}

.table--matrix td a.active,
.table--matrix td a.active:visited {
	background-color: #00aeef;
	color: $white;
}

/* =Basket tables
==========================================*/
.checkout-order-content{
	thead{
		tr{
			background-color: #45545f;
		}
	}
}


/* =Product Spec
==========================================*/

.ui-selectmenu-button.ui-state-default{
	font-weight: $regular;
}

.ui-selectmenu-menu .ui-menu .ui-menu-item.ui-state-active, .ui-selectmenu-menu .ui-menu .ui-menu-item.ui-state-focus{
	font-weight: $regular;
}

.ui-selectmenu-menu .ui-menu .ui-menu-item{
	font-weight: $regular;
}

.spec-builder--heading{
	margin-bottom: 0.5em;
	margin-top: 0;
}

.spec-builder .f-field{
	margin: 0 0 0.5em;
}

.ui-selectmenu-button.ui-state-default span.ui-icon{
	width: 2.9em;
}