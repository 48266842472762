/* =Primary Navigation
==========================================*/
.nav--primary {
	margin: 1em 0 0;
	position: relative;
	right: inherit;
	width: 100%;

	> input:checked + .nav--primary--links {
		display: flex;
		height: auto;
		position: relative;
	}

}


.nav--primary--toggle {
	display: none;
}

.nav--primary--links {
	background-color: transparent;
	display: flex;
	justify-content: flex-start;
	padding: 0;
	width: 100%;
	
	li{
		flex-grow: 1;
		flex-shrink: 1;
		margin-right: 2.5em;
		text-transform: uppercase;
		
		&:last-child{
			margin-right: 0;
		}

	}

	> li {
		flex-grow: 1;
		flex-shrink: 1;

		> a,
		> a:visited {
			padding: 0 0 2em;
			font-weight: bold;
			font-size: 14px;

			&.search-toggle {
				background-color: transparent;
				background-image: url('../img/svgs/icon-search.svg');
				background-position: right center;
				background-repeat: no-repeat;
				border: 0;
				border-radius: 0;
				display: block;
				flex-grow: 0;
				flex-shrink: 0;
				height: 24px;
				padding: 0;
				text-indent: -999em;
				text-transform: uppercase;
				width: 24px;
			}

		}

		&.with-sub {
			position: initial;
			display: flex;
			justify-content: flex-start;

			&:hover,
			&:focus {

				> a,
				> a:visited {
					color: #00aeef;
				}

				> .submenu {
					background-color: #1b1717;
					border: 2px solid $brand-primary;
					border-width: 2px 0 0;
					padding: 2em 1.5em 2em 2em;
					display: block;
					left: 0;
					position: absolute;
					top: 100%;
					width: 100%;
					z-index: 20;
					display: flex;
					flex-wrap: wrap;

					li{
						border-bottom: none;
						margin-bottom: 0.2em;
						width: 33.3%;
						text-transform: none;
						padding-right: 0.5em;
						
						&:last-child{
							width: 66.6%;
						}
			
						> a, 
						> a:visited {
							border: 0;
							color: #00aeef;
							padding: 0 .4em;
							font-size: 17px;
	 
							&:hover,
							&:focus {
								color: $white;
							}

						}
					}

					.with-sub {
						width: 100%;
					}
				}

			}

		}

		> .submenu {
			display: none;
	
			> li {
				margin: 0 0 0.5em;

				&:last-child {
					margin: 0;
				}
	
				> a,
				> a:visited {
					border-bottom: 1px solid $white;
					color: $white;
					margin: 0 0 1em;
					padding: 0 0 .8em;
	
					&:hover,
					&:focus {
						color: $brand-primary;
					}
	
				}
	
				> .submenu {
					align-items: flex-start;
					display: block;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
			
					> li {
						width: 33.3%;
			
						> a,
						> a:visited {
							border: 0;
							color: #00aeef;
							padding: .4em;
	
							&:hover,
							&:focus {
								color: $white;
							}

						}
			
					}
					
				}
	
			}
			
		}

	}
	
	li {
		border-width: 0;

		&.with-sub {

			a,
			a:visited {
				background-image: none;
			}
		}
	}
	
	a,
	a:visited {
		background-color: transparent;
		color: $text-color;
		display: block;
		padding: 0;

		&:hover,
		&:focus {
			background-color: transparent;
			color: $brand-primary;
		}
	}
	
}

.menu-slide--logo{
	display: none;
}

/* Desktop secondary nav */
.desktop-secondary--nav{
	align-items: flex-start;
	display: flex;
	justify-content: flex-end;
	left: 0;
	top: 0;
	
	.customer-account-buttons--container{
		display: flex;
		flex-wrap: wrap;
		width: auto;
	}
	
	.button-wrap{
		margin: 0;
		font-size: 1em;
		
		a{
			padding:0.945em 3em;
		}
	}
	
}

.button-wrap {
	
	&.account {
		border-left: 0.25em solid white;
		border-right: 0.25em solid white;
		width: auto;	
	}
	
	&.order {
		border-left: 0.25em solid white;
		border-right: 0.25em solid white;
		width: auto;	
	}
	
	&.logout {
		border-left: 0.25em solid white;
		margin: 0;
		width: auto;
	}
	
}

