.logo,
.logo:visited {
	float: left;
}

.mast {
	display: block;
	height: 161px;

	.wrap {
		@include cf;
		display: block;
	}
	
}

.mast--actions {
	float: right;

	.button-wrap {
		margin-top: 0;
	}

}

.mast--telephone {
	display: block;
}

.mast--basket {
	margin-top: 0;
}