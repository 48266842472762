/* =Primary Navigation
==========================================*/
.nav--primary {
	float: right;
	margin: 2.8em 0 0;
	position: absolute;
	bottom: 0em;
	right: 0;

}

.nav--primary--links {
	justify-content: flex-end;
	max-width: 600px;
	width: 70%;
	
	> li {
		flex-grow: 1;
		flex-shrink: 0;

		&:first-child {
			margin: 0;
		}

		> a,
		> a:visited {
			font-weight: $bold;
			padding: 0 0 2em;
		}

		&.with-sub {

			&:hover,
			&:focus {

				> .submenu {
					display: flex;
				}

			}

		}

		> .submenu {
			justify-content: flex-start;
			
			> li {

				&:last-child {
					
				}
	
				> a,
				> a:visited {
					
	
					&:hover,
					&:focus {
						
					}
	
				}
	
				> .submenu {
			
					> li {
			
						> a,
						> a:visited {
							
	
							&:hover,
							&:focus {
								
							}

						}
			
					}
					
				}
	
			}
			
		}

	}
	
}