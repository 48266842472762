.banner {
	align-items: stretch;
	display: flex;
	justify-content: flex-start;
}

.banner--content {
	flex-grow: 1;
	flex-shrink: 1;
}

.banner--promo {
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;

	width: 50%;
	justify-content: center;
	
	&.home{
		width: 100%;
	}
}

.banner--promo--inner {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.banner--promo .banner--promo--reg {
	margin-bottom: 2em;
}

.banner--promo .banner--promo--mini {
	margin-top: auto;
}



.banner--content.bg-image {
	background-position: left center;
}

/* =Slide Banners
==========================================*/
.banner--slide {
	align-items: center;
	background-position: left center;
	// background-position: right center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	display: flex;
	justify-content: flex-start;
	min-height: 500px;
	padding: 3em;
	position: relative;

	.button-wrap {
		margin: 2em 0 0;
	}

}

.banner--slide--logo {
	display: block;
}

.banner--slide--inner {
	max-width: 30em;
	width: 70%;
}

.banner--slide--heading {
	font-size: 3.375em;
}

.banner--slide--promo {
	
}

.banner--slide--promo--small {

}

.banner--slide--promo--lrg {
	font-size: 2.75em;
}

.banner--slide--content {
	display: block;
	
	p {

	}

}

.banner--slide--available {

}

.banner--slide--subhead {
	
}

.order-within{
	font-size: 1.125em;
}


.banner--promo .banner--promo--med {
	
	&.mobile--mini{
		font-size: 1.875em;
		display: block;
	}
}

.banner--promo .banner--promo--reg {
	
	&.mobile--mini{
		font-size: 1.375em;
		display: block;
	}
}
