/* =Primary Navigation
==========================================*/
.nav--primary {
	display: flex;
	justify-content: flex-end;
	position: absolute;
	right: 5%;
	
	> input:checked + .nav--primary--links {
		display: block;
		height: 100vh;
		left: 0;
		overflow-y: scroll;
		padding-top: 8em;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 3;
		
	}
	
	> input:checked + .nav--primary--toggle {
		background-color: #fff !important;
	}

}

.nav--primary--toggle {
	color: $white;
	cursor: pointer;
	display: block;
	position: relative;
	text-align: center;
	z-index: 5;

	&:hover,
	&:focus {
	}
	
	> input:checked + .nav--primary--toggle {
		background-color: #fff !important;
	}

}

#toggle-mobile-menu {
	left: -999em;
	position: absolute;
	top: 0;
}

.nav--primary--links {
	background-color: $brand-primary;
	display: none;
	list-style: none;
	margin: 0;
	padding: 1em;
	width: 0;
	transition: width 3s ease-in-out;

	li {
		margin: 0;
		border-bottom: 0.5px solid $white;
		
		&:last-child{
			border-bottom: none;
		}

		&.with-sub {

			a,
			a:visited {
				background-image: url('../img/svgs/icon-arrow-down.svg');
				background-position: right 1em center;
				background-repeat: no-repeat;
			}
			
			&.active{
				.submenu{
					display: block;
				}	
				
			}

		}

	}
	
	a,
	a:visited {
		background-color: $brand-primary;
		color: $white;
		display: block;
		font-size: 18px;
		padding: .5em 1em;
		font-weight: bold;

		@media only screen and (max-width: 62.5em) {
			font-size: 18px;
		}

		&:hover,
		&:focus {
			background-color: $link-hover;
		}

	}

	.submenu {
		display: none;
		list-style: none;
		padding: 0;

		> li {
			border-color: rgba($white, .5);

			a,
			a:visited {
				padding-left: 2em;
			}

			> .submenu {
		
				> li {
		
					a,
					a:visited {
						padding-left: 3em;
					}
		
				}
				
			}

		}
		
		&.digitalul{
			a{
				background-image: none;
			}
		}
		
	}
	
}


.mast-logo-wrap{
	width: 100%;
}

/* =Burger Container
==========================================*/

.burger-container {
	cursor: pointer;
  	display: inline-block;

}

.bar1, .bar2, .bar3 {
	background-color: #333;
  	height: 5px;
  	margin: 6px 0;
	transition: 0.4s;
	width: 35px;
	
}

/* Rotate first bar */
.change .bar1 {
	background-color: #fff;
  	-webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
	  transform: rotate(-45deg) translate(-9px, 6px) ;
	  
}

/* Fade out the second bar */
.change .bar2 {
	opacity: 0;
	  
}

/* Rotate last bar */
.change .bar3 {
	background-color: #fff;
  	-webkit-transform: rotate(45deg) translate(-8px, -8px) ;
	transform: rotate(45deg) translate(-8px, -8px) ;
	  
}

/* Desktop secondary nav */
.desktop-secondary--nav{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	
	.mast--basket{
		margin-bottom: 0;
		
		&.mobile{
			display: none;
		}

	}
	
	.customer-account-buttons--container{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
	
}

.button-wrap{
	&.account{
		width: 50%;
		border-right: 0.5em solid white;
	}
	
	&.order{
		width: 50%;
		border-left: 0.5em solid white;
	}
	
	&.logout{
		margin: 0;
		width: 100%;
	}
	
}

.signin{
	margin-bottom: 0;
	width: 100%;

}


@media only screen and (min-width: 53.125em) {

	@import 'nav-primary_850';

}

@media only screen and (min-width: 68.750em) {

	@import 'nav-primary_1100';

}