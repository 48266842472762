.site-search {
	background-color: $black;
	display: none;
	padding: 2em 0;

	&.active {
		display: block;
	}

}

.form--search {
	align-items: stretch;
	display: block;
	display: flex;
	justify-content: flex-start;
}

.form--search--input {
	display: block;
	flex-grow: 1;
	flex-shrink: 1;
}

.form--search--submit {
	background-color: $brand-primary;
	border: 0;
	border-radius: 0;
	color: $white;
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
	font-size: .813em;
	font-weight: $bold;
	padding: 1em 1.6em .8em;
	text-transform: uppercase;

	&:hover,
	&:focus {
		background-color: $link-color;	
	}

}