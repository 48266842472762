/*

	variable usage example:

		background-image: url('#{$url}/images/...');

*/



/* =Base
==========================================*/
body {
	color: $text-color;
	font-family: $fancy-sans;
	line-height: 1.6;
}


/* =Headers
==========================================*/
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	font-weight: $regular;
	text-rendering: optimizelegibility;

	a,
	a:visited {
		text-decoration: none;
	}

}

h1, .h1 {
	font-size: 2.5em;
	line-height: 1.333em;
}

h2, .h2 {
	color: #1b1717;
	font-weight: bold;
	font-size: 1.75em;
	line-height: 1.4em;
	margin-bottom: 0.375em;
	
		[data-background = "black"] &{		
			color: $white;
			
		}
}

h3, .h3 {
	font-size: 1.125em;
	font-weight: bold;
}

h4, .h4 {
	font-size: 1.1em;
	font-weight: 700;
}

h5, .h5 {
	font-size: 0.846em;
	line-height: 2.09em;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.content--heading {
	color: #1B1717;
	font-size: 2.375em;
	line-height: 1.2;
	margin: 0;
}



/* =Content
==========================================*/
p {
	hyphens: none;
	word-break: keep-all;
}

.content--intro {
	font-size: 1.2em;
	font-weight: $bold;
}

a,
a:visited {
	text-decoration: none;
	transition: color 0.3s ease-in-out;

	&:hover,
	&:focus {
		text-decoration: none;
	}

}

.content{
	h2{
		color: $brand-primary;
		font-size: 1.250em;
		margin-bottom: 1em;
	}
}

.content--title{
	color: $brand-primary;
	font-size: 1.250em;
	margin-bottom: 1em;

}

.prod-name{
	font-weight: $extra-black;
}

.service-list .service{
	font-weight: $bold;
}

.pp-price{
	color: $brand-primary;
	font-size: 22px;
}

.summary--head{
	padding-top: 1em;
	
	&.flat-top{
		padding-top: 0.5em;
	}
}

.po--number{
	margin-top: 0.5em;
}

/* =Accessible skip to content
==========================================*/
.content--skip-to,
.content--skip-to:visited {
	height: 1px;
	left: -999px;
	overflow: hidden;
	position: absolute;
	top: auto;
	width: 1px;
	z-index: -999;

	&:focus,
	&:active {
		background-color: hotpink;
		background-color: rgba(hotpink, .8);
		color: $white;
		left: 0;
		height: auto;
		margin: 0;
		padding: 1.5em;
		text-align: center;
		top: 0;
		width: 100%;
		z-index: 999;
	}

}


/* =Captions
==========================================*/
.wp-caption {
	background-color: darken($white, 3%);
	border: 1px solid darken($white, 10%);
	padding: .5em;
	margin: 1em 0 2em;
	max-width: 100%;

	img {
		display: block;
		height: auto !important;
		width: 100%;
	}

	&.alignleft {

		@media only screen and (min-width: 650px) {
			float: left;
			margin-right: 2em;
			max-width: 50%;
		}

	}

	&.alignright {

		@media only screen and (min-width: 650px) {
			float: right;
			margin-left: 2em;
			max-width: 50%;
		}

	}

}

.wp-caption-text {
	color: $text-color;
	margin: 0;
	padding: 1em 0;
}


/* =HRs - We don't really use these though
==========================================*/
hr {
	clear: both;
	display: block;
	margin: 2em 0;
}


/* =Lists
==========================================*/
ul,
ol {


	ul,
	ol {

	}

	> li {

		ul {
			> li {

			}
		}
	}

}

li {
	font-size: 1em;
	margin-bottom: .5em;
	position: relative;
}

ul {
	padding-left: 1.5em;

	> li {

	}

}

ol {

	> li {

	}

}

.list {

}

.list--sitemap {
	li {

	}
	a {

	}
	a:visited {

	}
}


/* =Blockquotes
==========================================*/
blockquote {

	p {

	}

	footer {

	}

	cite {

	}

}


/* =Code and Pre
==========================================*/
pre {
	background-color: lighten($black, 20%);
	padding: .4em 1em;
}

code {
	background-color: lighten($black, 20%);
	color: $white;
	font-size: .9em;
	padding: .4em;
}


/* =Text Tricks
==========================================*/
.screen-reader-text {
	display: block;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
}

.hide-text {
	display: none;
}



/* =Misc
==========================================*/

.paypal--text{
	display: block;
	font-weight: $bold;
	margin: 0 auto;
	max-width: 260px;
	padding-bottom: 1em;
	padding-top: 2em;
	text-align: center;
	
}

.forgot-password--text{
	display: block;
	font-size: 13px !important;
	margin-top: 1em !important;

}


.new-c-sign-up--container, .returning-c--container{
	p {
		margin-bottom: 0.5em;
	}
	
	ul{
		margin: 0;
		padding: 0 1em; 
		
		li{
			margin-bottom: 0.25em;
			padding-left: 0.75em;
		}
	}
}

.address-ID{
	font-size: 24px !important;
	font-weight: 800;
	margin: 0;
}

.dashboard-link--icon{
	display: block;
	padding-left: 2em;
	padding-top: 0.15em;
	transition: padding-left 0.25s ease-in-out;

}


#myList li{ display:none;
}
#loadMore {
    color:green;
    cursor:pointer;
}
#loadMore:hover {
    color:black;
}
#showLess {
    color:red;
    cursor:pointer;
    display:none;
}
#showLess:hover {
    color:black;
}