.accordion {
    background-color: #fff;
    border: none;
    border-bottom: 0.5px solid grey;
    color: #444;
    cursor: pointer;
    display: flex;
    font-size: 1.063em;
    font-weight: $bold;
    justify-content: space-between;
    padding: 10px 0;
    width: 100%; 
    text-align: left;
    outline: none;
    
    transition: all 0.4s ease-in-out; 
    
    &.active { 
        .faq--icon {
          transform: rotate(90deg);
          padding-right: 10px;
        }
    }
}

.active, .accordion:hover {
    color: $brand-primary;
}

.faq--icon {
    margin-right: 7px;
}

.panel {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition: max-height 0.2s ease-out;
    
    p {
        color: #000;
    }
}

.faq-accordion{
    list-style: none;
    padding-left: 0;
  
    li:nth-child(n+7){
        display: none;
    }
  
    &.active {
        li:nth-child(n+7){
            display: block;
        }
    } 
}

.view-more--button {
    background-color: #fff;
    color: #777;
    cursor: pointer;
    padding: 10px 0;
    margin-bottom: 1em;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 17px;
    font-weight: $semi;
    transition: 0.4s;
  
    &.active {  
        &:after {
          content: "\2212";
        }     
    }
    
    &:hover,
    &:focus {
        color: $text-color;
        text-decoration: underline;
    }
}

.view-more--button:after {
    content: '\002B';
    color: #777;
    font-weight: $bold;
    float: right;
    margin-left: 5px;
    border: 2px solid #777;
    padding: 0px 5px;
  
}


