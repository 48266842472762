/* =Base
==========================================*/
html {
	box-sizing: border-box;
	height: 100%;
	// NOTE - This is dependant on how the theme is to work. Browser support not perfect.
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}


body{
	&.active{
		overflow: hidden;

	}
}

/* =Strips
==========================================*/
.strip {
	clear: both;
	display: block;
	padding: 4em 0;
	
	&[data-pad="flat"] {
		padding: 0;
	}

	&[data-pad="half"] {
		padding: 2em 0;
	}
	
	&[data-pad="quarter"] {
		padding: 1em 0;
	}
	
	&[data-pad="half-flat-bottom"] {
		padding: 2em 0 0 0;
	}
	
	&[data-pad="flat-bottom"] {
		padding: 4em 0 0 0;
	}
	
	&[data-pad="flat-top"] {
		padding: 0 0 4em;
	}

	&[data-bg="black"] {
		background-color: #1B1717;
	}

}


[data-attribute="off"]{
	display: none;
}
/* =Background attributes
==========================================*/

[data-background="grey"] {
	background-color: $grey;
	
}

[data-background="lgrey"] {
	background-color: $lgrey;
	
}

[data-background="white"] {
	background-color: $white;
}

[data-background="black"] {
	background-color: $black;
}

/* =Product info
==========================================*/

.product-info--text {
	
	p{
		margin: 0;
	}
	
}

/* =Cost Matrix
==========================================*/

.cost-matrix--content {
	padding-bottom: 1em;
}

.notice-box {
	margin: 2em 0;
	padding: 1em 1.5em;

	p {
		margin: 0;
		
	}
	
}

/* =Checkout Bar
==========================================*/

.check-out-bar {

	@media only screen and (min-width: 948px) {
		font-size: 20px;
	
	}

	@media only screen and (min-width: 1100px) {
		font-size: 24px;
	
	}

}


/* =Artwork Selection
==========================================*/
.artwork--container {

	.artwork-select--child {
		align-items: center;
		border-bottom: 1px solid $grey;
		display: flex;
		justify-content: space-between;
		padding-top:0.2em;
		width: 100%;

		&:last-child {
			border-bottom: none;

		}

	}
	
}

.icon--container {
	display: flex;
	padding-top: 5px;

}

.icon--wrap {
	transition: margin-top 0.3s ease-in-out;
	margin-left: 10px;
	display: block;

	&:hover,
	&:focus {
		margin-top: -3px;

	}

	img {
		height: 25px;
		margin: 0 0.4165em;
		width: 25px;

	}

	&:last-child {

		img{
			margin-right: 0;

		}
	}
}


/* =FAQ Section
==========================================*/

.faq--container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.faq-child--wrap {
	align-items: center;
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	
	img {
		padding: 2em 0;
	}

	.faq--title {
		font-weight: bold;
		margin-bottom: 0;
	}

	p {
		margin-top: 0.5em;
		max-width: 90%;
	}

	.faq-icon--wrap {
		align-items: center;		
		display: flex;
		height: 120px;
		justify-content: center;
	}
}


/* =Checkout Section
==========================================*/

.general-full {
	padding: 0;	
}

.checkout-order-content {
	display: flex;	
}

.checkout-order-content {
	table{
		padding-top: 0;
		margin: 0;	
	}	
}

.checkout-order-content table thead {
	background: $black;	
}

/* =Basket page
==========================================*/


.basket-process-timeline {
	display: flex;
	justify-content: space-between;
	
}

.basket-process-timeline--container {
	justify-content: space-between;
	
	.multi-step-bar {
		counter-reset: step;
		display: block;
		margin: 0;
		overflow: hidden;
		padding-left: 0;
		padding-top: 2em;
		width: 100%;
	
		li {			
			color:  #CCCCCC;
			float: left;
			font-weight: 600; 
			font-size: 15px;
			list-style-type: none;
			position: relative;
			text-align: center;
			width: 20%;
			
			&.active{
				color: #98C21D;
				
				&:after {
					background:  #98C21D;
					content: '';
					height: 2px;
					left: -50%;
					position: absolute;
					top: 15px;
					width: 100%;
					z-index: -1; 
				}
			}
		}

		li:before {
			background:  #CCCCCC;
			border-radius: 50%;
			content: counter(step);
			color: $white;
			counter-increment: step;				
			display: block;
			font-size: 12px;
			line-height: 30px;
			margin: 0 auto 5px auto;
			width: 30px;
			
		}

		li:after {
			background:  #CCCCCC;
			content: '';
			height: 2px;
			left: -50%;
			position: absolute;
			top: 15px;
			width: 100%;
			z-index: -1; 
			
		}

		li:first-child:after {	
			content: none; 
			
		}

		li.active:before {
			background:#98C21D;
			color: white;
			
		}
		
	}
	
}

.basket--container {
	display: flex;
	flex-wrap: wrap;
	
	h3 {
		color: $brand-primary;
		font-size: 20px;
		margin: 0;
		
	}
}

.basket-details--container {
	width: 100%;


}

.prod-details--head {
	background-color: $black;
	color: $white;
	display: flex;
	font-size: 13px;
	justify-content: space-between;
	

	.text-wrap--l {	
		padding: 0 1em;
		width: 55%;
	}
	
	.head-container {	
		display: none;
		widtH:40%;
		margin-right: 1em;
	}
	
	.text-wrap--s {
		text-align: center;
		width: 33%;
	}
	
}
	
.prod-details--container{
	background-color: $white;
	padding: 0 1em;
	
	.prod-details--child {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #EDEDED;
		
		.product--info {
			font-size: 14px;
			padding: 0.5em 0 0.5em;			
			width: 100%;

		}
		
		.ob--text {
			color: #98C21D;
			font-size: 14px;
		}
		
		.separator {
			color: $brand-primary;
			padding: 0 0.25em;
		}
		
		.product-info--container-desktop {
			width: 40%;
			display: none;
			font-size: 13px;
		}
		.product--quantity {
			align-items: flex-start;
			display: flex;
			width: 20%;
			margin: 0;
			
			p {
				margin: 0;
				margin-right: 5px;
			}

		}
		
		.product--total {
			align-items: flex-start;
			display: flex;
			justify-content: center;
			width: 80%;
			
			p {
				margin: 0;
				margin-right: 5px;
			}
			
		}
		
		.remove {
			align-items: flex-end;
			display: flex;
			justify-content: flex-end;
			padding: 1em 0 1.5em;
			margin: 0;
			margin-right: 1em;
			width: 10%;
			
			p{
				margin: 0;
				
			}
		}
		
	}
}

.remove {
	margin-right: 1em;
}

.order-summary--options {
	width: 100%;
}

.total--container {
	background: $black;
	display: flex;
	justify-content: space-between;
	margin-top: 1em;
	padding: 1.5em 1em;
	width: 100%;
	
	.text-wrap {
		
	}
	
	.total-head--text {
		color: $brand-primary;
		font-size: 13px;
		margin: 0;
	}
	
	.total--text {
		color: $white;
		font-size: 30px;
		line-height: 1;
		margin: 0;
		
	}
}

.order-summary--container {
	background-color: $white;
	padding: 1em 1em 0 1em;
	
	h3{
		margin: 0;
		color: $brand-primary;
	}
	
	.summary--child {
		border-bottom: 1px solid #EDEDED;
		display: flex;
		font-size: 17px;
		font-weight: $bold;
		justify-content: space-between;
		
		&:last-child{
			border-bottom: none;
		}
	}
}


.delivery-options--container {
	background-color: white;
	font-weight: $bold;
	margin: 1em 0;
	padding: 1em;
	
	h3{
		color: $brand-primary;
		margin: 0;
		
	}
	
	p{
		font-size: 13px;
		margin: 0;
		padding: 0.5em 0;
	}

	
	select {
		background-color: $grey;
		background-image: url('/img/svgs/icon-select-arrow-down.svg');
		background-position: 100%;
		background-size: 44px;
		cursor: pointer;	
		font-size: 13px;
		height: 42px;

	}
	
}

.basket-notice--container {
	background-color: #FFF3F3;
	border: 1px solid #FF321D;
	font-size: 13px;
	margin-top: 1em;
	padding: 1em 1.5em; 
	
	.notice--head {	
		color: #FF321D;
		font-weight: $bold;
		margin: 0;
		padding: 0.5em 0;
		text-transform: uppercase;
	}
	
	p {
		font-weight: $bold;
		line-height: 1.4;
		margin: 0;
		padding-bottom: 0.5em;
	}
	
}


.product-info--container-mobile {	
	display: flex;
	font-size: 13px;
	flex-wrap: wrap;
	justify-content: space-between;

	
	.production--notice {
		width: 80%;
	}

	.product--quantity {
		justify-content: flex-start;
		
		p{
			margin-left: 5px;
			
		}
	}
	
}

.production--notice-desktop {
	display: none;
}


.remove-icon--wrap{
	background-color: #CCCCCC;
	cursor: pointer;
	padding: 7.5px 7.5px 3px 7.5px;
	
	transition: background-color $transition $easing,
		color $transition $easing;
	
	&:hover,
	&:focus{	 
		background-color: #98C21D;
		 
	}
}

.popular-center{
	padding: 0 5%;
}

.product--wrap{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

}


.content-one-third{
	padding: 0;
	display: flex;
	min-height: 0;
	
	p{
		&:first-child{
			margin-top: 0;
		}
	}
}

.content-two-third{
	
	p{
		&:first-child{
			margin-top: 0;
		}
		
		&:last-child{
			margin-bottom: 0;
		}
	}
	
}

.reviews-slide--wrap{
	display: flex;
	justify-content: center;
}

.review--child{
	text-align: center;
	padding: 0 2.5em;
	margin-top: 4em;
}


/* =Flex slider
==========================================*/

.flex-control-nav{
	.google &{
		display: none;	
	}
}

.review-slider--footer{
	display: flex;
	align-items: center;
	justify-content: center;
	
	hr{
		width: 90%;
		max-widtH: 1260px;
		margin: 0;
		color: #eeeeee;
		position: absolute;
		z-index: -1;
	}
}

.logo--container{
	padding-top:10px;
	
	svg{	
		width: 91px;
	}

}

.google-footer--wrap{
	display: flex;
	align-items: center;
	background-color: white;
	padding: 1em 2em;
	
	&:before{
		
	}
	
	.rating--text{
		color: #E7711B;
		font-size: 24px;
		font-weight: $bold;
		margin-left: 1em;
		margin-right: 0.25em;
	}
	
	.star-rating{
		margin-right: 2em;
	}
}

/* =Login Styling
==========================================*/

.customer_register input[type="password"], .customer_register input[type="text"]{
	border: none;
}

.general-center{
	[data-background="lgrey"] &{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

.general-half{
	h2{
		font-size: 24px;
		font-weight: $extra-black;
		margin: 0;
		
	}
	
	h3{
		font-size: 24px;
		font-weight: $regular;
		margin: 0;
		
	}

	[data-background="lgrey"] &{
		background-color: $white;
		margin: 0 0 1em;
		padding: 1em 5%;
		width: 100%;
		
		&:first-child{
			margin-top: 1em;
		}

	}
	
	.link_button{
		background-color: #00aeef;
	}
}

.lost-password-link{
	
	&:hover,
	&:focus{
		text-decoration: underline !important;
	}
	
}

.customer_register input[type="submit"]{
	margin-top: 2.4em;
	transition: background-color $transition $easing,
				color $transition $easing;
				
		&:hover,
		&:focus {
			background-color: rgb(85, 85, 85);
		}
		
}

.customer_register{
	margin-top: 0;
	
	label{
		font-size: 13px;
		font-weight: bold;
	}
}


.new-c-sign-up--container, .returning-c--container{
	p {
		margin-bottom: 0.5em;
	}
	
	ul{
		margin: 0;
		
		li{
			margin-bottom: 0.25em;
		}
	}
}

.customer-account-buttons--container{
	display: none;
}

.menu-slide--logo{
	margin-bottom: 10px;
	width: 170px;
	position: absolute;
	top: 1.5em;
	left: 0;
	padding-left: 5%;
	display: block;
}

/* =Address Container
==========================================*/
.address--container {
	padding: 1em 1em;
	width: 100%;
	
	h2{
		margin: 0;
	}
}

.address-child--container {
	display: flex;
	widtH: 100%;
	
	.address{
		font-size: 17px;
		font-weight: bold;
	}
		
}

.address--child {
	background-color: $white;
	display: block;
	padding: 1em 1em 3em;
	margin-bottom: 1em;
	width: 100%;
	
	h2{
		margin-top: 0;
	}
	
}

.address-button--wrap {
	display: flex;
	flex-direction: column;
	
	a {
		background-color: $white;
		color: $brand-primary;
		font-size: 13px;
		font-weight: bold;
		
		&:hover,
		&:focus{
			text-decoration: underline;
			background-color: $white;

		}
		
	}
	
	.billing_address {
		color: $brand-primary;
		padding: 0.5em 0;
		text-transform: inherit;
		
		&:hover,
		&:focus{
			text-decoration: underline;
			color: $brand-primary;
		}	
		
	}
	
	.delivery_address {
		color: $brand-primary;
		padding: 0;
		text-transform: inherit;
		
		&:hover,
		&:focus{
			text-decoration: underline;

		}
		
	}
}

.default{
	margin: 0;
	padding: 0.75em 0;
	font-size: 13px !important;
	font-weight: $bold;
	text-decoration: underline;
}

.account-details{
	.td_label{
		font-size: 13px;
		font-weight: $bold;
		
	}
	
	.td_input{
		display: block;
		margin-bottom: 14px;

	}
}

.account-details--container{
	background-color: #ffffff;
	margin: 2em 0;
	max-width: 630px;
	padding: 1em 1em 2em;
	
	h2{
		margin-top: 0;
	}
	
	a.proceed_left{
		width: 100%;
		margin-top: 30px;
		padding: 6px 20px;
	}
}

.address-book--button{
	margin-top: 0 !important;
	
}

.special-instructions--container{
	background-color: #ffffff;
	padding: 1em;
	
	h3{
		color: $brand-primary;
		font-size: 1.5em;
		margin: 0;
	}
	
	.orders &{
		padding: 0.5em 0;
		
		p{
			font-size: 14px;
		}
	}
	
}
/* =Paypal
==========================================*/

.paypal--container {
	font-weight: $bold;
	padding: 0 3.5em;
	text-align: center;
}


/* =Upload Artwork Head
==========================================*/

.info--wrap {
	background-color: $white;
	font-weight: bold;
	font-size: 17px;
	margin-bottom: 1em;
	padding: 2em 5% 2em;

	
	.red {
		background-color: #db3020;
		color: $white;
		padding: 5px 10px;
		
		p {
			margin: 0;
		}
	}
	
	a {
		color: $brand-primary;
		cursor: pointer;
		text-decoration: underline;
		
		&:hover,
		&:focus {
			color: #015b7c;
			text-decoration: none;
		}
	}
}

.file-hint--container {
	background-color: $brand-primary;
	padding: 0.5em 5% 2em;
	color: $white;
	
	h3 {
		color: $white;
		font-size: 20px;
		margin-bottom: 0;
	}
	
	.black {
		color: $black;
		font-weight: $bold;
	}
	
}

.order-no {
	font-size: 20px;
	padding-top: 0.5em;
	widtH: 100% !important;
	
}

.order-status, .order-date{
	color: $white !important;
	font-size: 13px !important;
	text-align: left !important;
	margin: 0;
	padding: 1em 0 0.5em;
	width: 100% !important;
	
	.large{
		color: $white !important;
		font-size: 20px;
	}
	
	a{
		&:hover,
		&:focus{
			color: $brand-primary !important;
			text-decoration: underline;
		}
		
	}
}

.order-date{
	border-bottom: 1px solid white;
	padding-bottom: 1em;
	padding-top: 0.25em;
	text-align: left !important;
}


.order-info {
	width: 100% !important;
	
	.strong {
		font-size: 15px;
	}
	
	p{
		font-size: 14px;
		margin: 0;
	}
	
	.separator{
		color: $brand-primary;
	}
	
	.price--text{
		padding: 10px 0;
	}
	
}

.order-item--wrap{
	background-color: $white;
	padding: 0 1.25em;
	
}

.order-upload{
	padding-top: 1em;
}

.upload-files{
	width: 100%;
}

.product--info {
	margin: 0em 0 0.75em !important;
	width: 90%;
	
}

.order-item {
	background-color: $white !important;
	padding: 1em 0;
	border-bottom: 1px solid $grey;
	margin-bottom: 1em;
}

.order-buttons{
	display: block;
	padding-bottom: 1em;
}

.download-invoice {
	align-items: center;
	color: $brand-primary;
	display: flex;
	text-decoration: underline;
	transition: color 0.3s ease-in-out;
	
	.mast--basket--icon{
		margin-right: 0.7em;
		transition: fill 0.3s ease-in-out;
		
	}
	
	&:hover, 
	&:focus  {	
		color: #015b7c;
		
		.mast--basket--icon{
			svg{
					fill: #015b7c;
			}
			
		}
			
	}
	
}

.arrow--wrap {
	display: block;
	margin-lefT: 10px;
	margin-top: 3px;
}

.edit-button {
	color: $brand-primary;
	font-size: 0.813em;
	font-weight: bold;
	text-decoration: underline;
	
	&:hover,
	&:focus {
		text-decoration: none;	
	}	
}

.grid--content {
	padding-top: 1em;
	
	h2 {
		font-size: 1.5em;
		font-weight: 800;
		margin: 0;
	}
	
	h3 {
		font-size: 1.5em;
		font-weight: $regular;
		margin: 0;
		
	}
	
	&.success {
		p {
			font-weight: bold;
		}	
	}
	
	.button-wrap {
		margin-top: 2em;
		margin-bottom: 1.5em;
	}
}

.art-text--wrap {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	min-height: 310px;
	text-align: center;
	
	h3 {
		color: $brand-primary;
		font-size: 1.250em;
		margin-bottom: 0.25em;
	}
	
	p {
		display: flex;
		font-weight: $extra-black;
		margin: 0.4em;
		font-size: 1.063em;
			
		&:before {	
			background-image: url('/img/svgs/icon-checkmark-green.svg');
			background-size: 100%;
			background-repeat: no-repeat;
				content: '';
			display: block;
			height: 18px;		
			margin-right: 0.5em;
			margin-top: 5px;
			width: 18px;
		}
	}
}

/* =Contact page
==========================================*/

.faq--cont, .dashboard-links--cont {
	background-color: $white;
	padding: 1em 2em;
	
	h3 {
		color: $brand-primary;
		font-size: 1.250em;
	}
}

.dashboard-links--cont {
	margin-top: 2.5em;
	padding-bottom: 2.5em;
	
	.button-wrap {
		display: block;
		margin-top: 2em;
		
	}
}

.dashboard--links {
	display: block;
	list-style: none;
	margin-top: 23px;
	padding: 0;	
	
	li {
		text-decoration: none;
		margin: 12.5px 0;
		
		a {
			color: $black;
			display: flex;
			font-size: 1.063em;
			font-weight: $bold;
			text-decoration: none;
		}
		
		&:hover,
		&:focus {
			
			a {
				color: $brand-primary;
				text-decoration: underline;
			}
			
			.dashboard-link--icon {
				padding-left: 3em;
			}	
		}
	}
}

.contact-form--cont {
	padding: 2.5em 2em;
	
	h2{
		margin: 0;
	}
	
	.save-add--button{
		margin-top: 2em;
	}

	.field-response-success{
		color: white;
		padding-top: 2em;
	}
}

.contact-cta--img{
	height: 330px;
	padding-bottom: 0;
}


.supporting--img {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	min-height: 200px;
	width: 100%;
}

.text-container{
	padding: 1em 2em 2.5em;
	display: block;
	
	h2{
		color: $brand-primary;
		font-size: 30px;
		line-height: 1;
	}
	
	.button-wrap{
		display: block;
		margin-top: 2em;
		
		a{
			max-width: 293px;
		}
	}
}

/*= Footer
==========================================*/
.footer-bottom{
	.copyright{
		color:#000;
		padding: 4px 30px 5px;
	}
}


@media only screen and (min-width: 37.500em) {

	@import 'base_600';

}


@media only screen and (min-width: 43.75em) {

	@import 'base_700';

}

@media only screen and (min-width: 56.250em) {

	@import 'base_900';

}

@media only screen and (min-width: 71.875em) {

	@import 'base_1150';

}