.order-details{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 1em !important;
}


.order-date{
    width: 50% !important;
    border-bottom: 0;
    padding: 0;
    text-align: right !important;
    line-height: 0;
    margin-top: -3px;
    
}


.order-no{
    padding-top: 0;
    width: 40% !important;
}

.info--wrap{
    padding: 2em 20px;
}

.file-hint--container{
    padding: 0.5em 20px 2em;
}

