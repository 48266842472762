.logo,
.logo:visited {
	display: block;
	width: 205px;

	img {
		display: block;
	}

}

