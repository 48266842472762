.wrap {
	margin: 0 auto;
	padding: 0 5%;

	@media only screen and (min-width: $wrap-break) {

		max-width: $wrap-restrict;
		padding-left: 0;
		padding-right: 0;

	}
	
	&.mobile{
		padding: 0;
		
		@media only screen and (min-width: 43.75em) {

			padding: 0 5%;

		}
		
		@media only screen and (min-width: 1308px) {

			padding: 0;

		}
		
		
	}
	
	&.delivery{
		padding: 1em 5%;
		
		@media only screen and (min-width: 850px) {
			display: flex;

		}
		
		@media only screen and (min-width: $wrap-break) {
			max-width: $wrap-restrict;
			padding-left: 0;
			padding-right: 0;

		}
			
	}

}
