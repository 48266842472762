.info--wrap {
    margin-bottom: 0;
    padding: 1.5em;
    width: calc(66.7% - 1em);
    
}

.file-hint--container {
    padding: 0 1.5em 1em;
    width: 33.3%;
    
}


.u-artwork--head {
    display: flex; 
    justify-content: space-between;
   
}

.order-item--wrap{
    padding: 0 1.5em;
    
} 

.order-item {
    position: relative;
    padding: 2em 0;
    
}

.order-no {
    width: 40% !important;
    
}

.order-date {
    margin-top: -2px;
    padding-lefT: 2em;
    width: 20% !important;
   
}

.order-status {
    margin: -2px 0 0;
    max-width: 242px;
    padding: 0;
    width: 30% !important;
   
}

.order-buttons {
    display: flex;
    margin-top: 1em;
    padding-bottom: 1em;
    width: 100%; 
    
    a {
        margin-right: 4em;
    }
    
}

.order-info {
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px;
    
    strong {
        line-height: 1;
        widtH: 90%; 
    }
    
    .product--info {
        margin: 0 !important;
        padding: 0;
        line-height: 1;
        width: 35%;
        padding-top: 5px;  
    }
    
    .quantity--text {
        padding-top: 8px;
        margin-left: 3em;
    }
    
    .price--text {
        padding-top: 8px;
        margin-left: 3em;
    }
     
}

.order-upload {
    position: absolute;  
    top: 2em;
    right: 0;
    width: auto !important;
    padding-top: 0;
}

.order-status {
    text-align: right !important;
}

.orders a.upload-files {
    width: 190px;
}

.delivery-options--container {
    margin-top: 0;
}

.order-number {
    margin-bottom: 1em;
}

.dashboard-links--cont {
    margin-top: 0;
    min-height: 463px;
    position: relative;
    
    .button-wrap{
        margin-top: 2.5em;
    }
}

.contact-form--cont {
    
    textarea{ 
        height: 159px;
    }
    
}