img {

	&.alignleft {

		@media only screen and (min-width: 650px) {
			float: left;
			margin-bottom: 1em;
			margin-right: 2em;
			max-width: 50%;
		}

	}

	&.alignright {
		
		@media only screen and (min-width: 650px) {
			float: right;
			margin-bottom: 1em;
			margin-left: 2em;
			max-width: 50%;
		}

	}

}


/* = JS Background image - Hides image and sets as parent background
==========================================*/
.bg-image {
	background-position: center center;
	background-size: cover;
	display: block;
	padding-bottom: 50%;
}

.bg-image--src {
	display: none;
}
