%btn {
	background-color: #00aeef;
	border: 0;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-size: .813em;
	font-weight: $bold;
	padding: 1em 1.6em;
	position: relative;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	transition: background-color $transition $easing,
				color $transition $easing;

	&:hover,
	&:focus {
		background-color: $link-hover;
		color: $white !important;
		text-decoration: none;
	}

	&:active {

	}

	&[data-colour="black"] {
		background-color: #000;

		&:hover,
		&:focus {
			background-color: rgb(85, 85, 85);
		}

	}

}

.button-wrap {
	
	a,
	a:visited,
	span {
		@extend %btn;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
	}

	&.proceed{
		a{
			padding: 0.9em 3em;
			font-size: 13px;
		}
		
	}
	
	svg{
		margin-left: 1em;
	}
}

.spec-actions--button,
.spec-actions--button:visited {
	@extend %btn;
	font-size: 0.813em;
	padding: 1.6em;
}

.icon-wrap{
	margin-top: 4px;
	display: block;
	margin-left: 7px;
}

.order-left{
	background-color: #EDEDED;
	color: $black;
	display: flex;
	justify-content: center;
	height: 50px;
	align-items: center;
	
	transition: background-color $transition $easing,
			color $transition $easing;
	
	&:hover,
	&:focus{

	}
	
	
	.icon-wrap{
		margin-righT: 15px;
	}
}


.proceed{
	.checkoutstepsummary &{
		display: none;
	}
}